.signOut-container {
  min-width: 320px;
  height: 672px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
  width: 80vw;
}
.sigOut-modal-title {
  color: #6b3378;
}
.button-container-signout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.button-container-signout a {
  font-weight: 700;
  font-size: 16px;
  color: #e33d8c;
}
@media (min-width: 1400px) {
  .signOut-container {
    width: 1056px;
  }
}
