.header_container {
  background-color: var(--header);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  margin: 0 auto 5rem auto;
}

/* .logo-header {
  width: 18rem;
} */
.logo-header img {
  min-width: 100%;
  height: 4rem;
}

.nav-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 0%;
  z-index: 400;
}

.button-nav-bar {
  position: relative;
  width: 2rem;
  height: 2rem;
  z-index: 200;
  cursor: pointer;
}

.button-nav-bar::after,
.button-nav-bar::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: 90%;
  height: 3px;
  border-radius: 1rem;
  background-color: var(--superLigth);
}

.button-nav-bar::after {
  top: 25%;

  transition: all;
  transition-duration: 0.5s;
}

.button-nav-bar::before {
  bottom: 25%;
  transition: all;
  transition-duration: 0.5s;
}

.acaunt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  border-top: 2px solid #ffffff45;
  width: 100%;
  padding: 2rem 0;
}

.acaunt a {
  text-decoration: none;
  color: var(--secondText);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.4rem;
  cursor: pointer;
}
.acaunt svg {
  height: 1.6rem;
  width: 1.4rem;
}
.icon-profile p {
  max-width: 7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.nav {
  position: fixed;
  top: 0%;
  right: 0%;
  left: 140%;
  bottom: 0;
  background-color: var(--header);
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: left 0.5s;
  z-index: 150;
}
.nav-link {
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.nav-item {
  position: relative;
  width: 6rem;
  color: var(--secondText);
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  padding: 0.3rem 0;
  font-size: 16px;
  cursor: pointer;
}
.nav-item:hover {
  color: var(--secondText);
  text-decoration: none;
  background: rgba(246, 237, 247, 0.2);
  border-radius: 4px;
}
.nav-item-active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -5px;
  width: 100%;
  height: 4px;
  background-color: #e33d8c;
}

.nav-item {
  cursor: pointer;
  text-decoration: none;
  color: var(--secondText);
}
.header-events-types-container {
  position: relative;
  width: 8rem;
}
.header-events-types {
  position: absolute;
  top: 100%;
  height: 0rem;
  overflow: hidden;
  transition: all 0.5s;
  background-color: var(--superLigth);
  width: 8rem;
  border-radius: 16px;
  color: var(--text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0px 4px 11px -10px #000;
}
.header-events-types .header-nav-item {
  color: var(--text);
  width: 100%;
  padding: 0.3rem 0.7rem;
}
.header-events-types .header-nav-item:hover {
  color: var(--secondText);
  background-color: var(--secondaryColor);
  border-radius: 10px;
}

.header-events-types-container > .header-nav-item {
  margin: 0;
  width: 100%;
  margin: auto;
}
.header-events-types-container .header-nav-item > p {
  margin: 0 auto;
}
.header-lenguage {
  position: relative;
}

.header-lenguage::after {
  content: url("https://api.iconify.design/material-symbols/arrow-back-ios-rounded.svg?color=%23e33d8c");
  position: absolute;
  color: var(--secondaryColor);
  margin: 0 0 -2px 0.5rem;
  top: -2px;
  left: 76%;
  height: 100%;
  width: 1rem;
  transform: rotate(-180deg);
  transition: all 0.5s;
}

.active-header-events-types .header-events-types {
  height: 11rem;
  padding: 0.5rem;
}

.active-header-events-types .header-lenguage::after {
  transform: rotate(-90deg);
  top: -3px;
}

.close-nav {
  opacity: 0;
}

.active {
  position: fixed;
  left: 50%;
}

.active .button-nav-bar {
  right: 5%;
}

.active .button-nav-bar::after {
  transform: rotate(45deg);
  top: 45%;
  transition: all 0.5s;
}

.active .button-nav-bar::before {
  transform: rotate(-45deg);
  bottom: 45%;
  transition: all 0.5s;
}

.active .nav {
  position: fixed;
  left: 22%;
  right: 0%;
  transition: left 0.5s;
}

.active .close-nav {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.057);
  -webkit-backdrop-filter: blur(2px) brightness(0.5);
  backdrop-filter: blur(2px) brightness(0.5);
  z-index: 100;
  opacity: 1;
  transition: all 1s;
}
.header-nav {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}
@media (min-width: 480px) {
  .active .nav {
    left: 60%;
  }
}
@media (min-width: 1080px) {
  .nav-container {
    position: unset;
    width: 50%;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .active .button-nav-bar,
  .button-nav-bar {
    display: none;
  }

  .active .nav,
  .nav {
    position: unset;
  }

  .active .close-nav,
  .close-nav {
    display: none;
  }
  .logo-header {
    flex-basis: 20%;
  }
  .nav {
    width: 100%;
    height: 3.75rem;
    background-color: unset;
    flex-direction: row;
    padding-top: 0;
    margin: 12px 0;
    justify-content: center;
    align-items: center;
  }
  .nav-link {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    flex-basis: 80%;
  }

  .acaunt {
    flex-direction: row;
    /* flex-wrap: wrap;
    min-width: 18rem; */
    border: none;
    justify-content: flex-end;
    padding: 0;
    flex-basis: 20%;
  }
}
/* 
@media (min-width: 1440px) {
  .logo-header {
    min-width: 18rem;
  }
} */
.main {
  width: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.header-number-invitation {
  position: relative;
}
.header-number-invitation svg {
  color: var(--secondText);
}

.hover-total-invitation {
  position: absolute;
  top: 1.5rem;
  right: -2.5rem;
  height: 4rem;
  overflow: hidden;
  transition: all 0.4s;
  display: flex;
  align-items: flex-end;
  text-align: center;
  width: 0;
}
.hover-total-invitation-display {
  padding: 10px;
}
.hover-total-invitation > p {
  background: var(--secondLigth);
  color: var(--secondText);
  height: 3rem;
  border-radius: 8px;
  z-index: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.hover-total-invitation::after {
  position: absolute;
  content: "";
  top: 0.5rem;
  right: 2.7rem;
  height: 1rem;
  width: 1rem;
  background-color: var(--secondLigth);
  z-index: 0;
  transform: rotate(45deg);
}
.header-total-invitation-icon-container {
  cursor: pointer;
}

.hover-true {
  width: 10rem;
}
.number-total-Invitation {
  top: -8px;
  position: absolute;
  border-radius: 50%;
  color: var(--secondText);
  background-color: red;
  right: -13px;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 0;
  min-height: 20px;
  min-width: 20px;
}
.announcement-container {
  background-color: var(--superLigth);
  padding: 1rem;
  display: grid;
  place-content: center;
}
.announcement-text {
  color: var(--secondaryColor);
  font-weight: 700;
  margin: 0;
}
.announcement-text span {
  /* background: var(--fuchsiaHover); */
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
}
@media (max-width: 1080px) {
  .header-events-types-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .header-events-types {
    position: absolute;
    /* top: 10%; */
  }
}
