.templates-container {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 0rem;
  justify-content: space-between;
  min-height: 100vh;
}
.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.filter-container > * {
  border-top: 1px solid #a8bcd3;
}
.filter-container > :first-child {
  border-top: none;
}
.filters-container {
  flex-basis: 100%;
}

.tag-type {
  background-color: inherit;
  cursor: pointer;
  position: relative;
  z-index: 10;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #0d060f;
  padding: 0.5rem 0 0.5rem 0;
}
.tag-type::after,
.tag-type::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 11.5px;
  background: #e33d8c;
  margin: auto;
  transition: all 0.5s;
}
.tag-type::after {
  height: 1.5px;

  transform: rotate(90deg);
}
.tag-type::before {
  height: 2px;
}

.tags {
  position: relative;
  font-size: 16px;
  text-transform: capitalize;
  color: #5e2d69;
  opacity: 0;
  transition: all 0.5s;
  margin: 0 0 1rem 1rem;
}
.tag {
  cursor: pointer;
  position: relative;
  margin: 0;
}

.tag:hover {
  font-weight: 700;
}
.tag-container {
  position: relative;
  top: 0px;
}
.title-filter {
  font-weight: 700;
  font-size: 20px;
  margin: 1rem 0;
}
.title-filter ~ div {
  min-height: 2rem;
}
.none {
  visibility: hidden;
  margin: -200% 0 0 0;
  transition: all 0.5s;
  transition-delay: 0.4s;
}
.view ~ div {
  margin: 0;
  visibility: visible;
  transition: all 0.5s;
  transition-delay: 0s, 0.4s;
}
.view::after {
  transform: rotate(0deg);
  transition: all 0.5s;
}
.view ~ .tags-container .tags {
  opacity: 1;

  transition-delay: 0.5s;
}
.tag-active {
  font-weight: 700;
  position: relative;
}
.tag-active::after
/*, .tag:hover::after */ {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 16px;
  background: #e33d8c;
}

.color .tag {
  height: 19px;
  width: 19px;
  border-radius: 50%;
  margin: auto;
}
.color .tags {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 12px 0;
  gap: 14px;
}
.shape .tags {
  display: flex;
  justify-content: space-around;
  margin: 1rem;
}
.shape .tag:last-of-type {
  height: 49px;
  width: 32px;
  background-color: #6b3378;
  color: transparent;
}
.shape .tag:first-of-type {
  width: 49px;
  height: 32px;
  background-color: #6b3378;
  color: transparent;
}

/* Template Board */
.templates {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-template-rows: repeat(auto-fit, 450px);
  gap: 4rem 3rem;
  justify-content: space-between;
}

.template-error-404-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #069996;
}
.template-error-img {
  width: 60%;
}

.template {
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  height: 100%;
  cursor: zoom-in;
  box-shadow: 1px 1px 10px 1px #00000054;
  border-radius: 12px;
  width: 100%;
}
.template .no-animated-container {
  transform: scale(0.8);
}

.template p {
  margin: 1rem 0;
  font-size: 18px;
  font-weight: 600;
}
.title-templateBoard {
  text-align: center;
  margin: 1rem auto 7rem auto;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.template .invitation--frame {
  height: 200px;
  width: 200px;
}
.template .postcard--postal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.template-image {
  width: 100%;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 10px grey;
}
.template-board-container {
  flex-basis: 100%;
}
@media (min-width: 450px) {
  .filters-container {
    flex-basis: 100%;
  }
  .template-board-container {
    flex-basis: 100%;
  }
  .templates {
    justify-content: space-between;
  }
}
@media (min-width: 850px) {
  .filters-container {
    flex-basis: 15%;
  }
  .template-board-container {
    flex-basis: 70%;
  }
}
.template-steps-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}
.template-next {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
}
.template-next p,
.template-before p {
  cursor: pointer;
}
@media (min-width: 1080px) {
  .template-board-container {
    flex-basis: 75%;
  }
}

.modal-container-template {
  display: flex;
  flex-direction: column;
  gap: 3rem 0;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-invitation-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-invitation-container .no-animated-container:first-child {
  scale: 0.8;
}
@media (min-width: 480px) {
  .modal-invitation-container:first-child {
    scale: 1;
  }
}
.modal-invitation-container-two {
  text-align: center;
}
.modal-invitation-container-two > p {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}
