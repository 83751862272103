.faqs-container {
  border-radius: 4px 4px 0 0;
  display: flex;
  height: 98%;
  gap: 2rem;
}
.hidden-navbar {
  display: none;
}
.faqs-search-input {
  width: inherit;
}

.faqs-info {
  display: flex;
  flex-direction: column;
}
.most-faqs {
  position: relative;
  min-height: 3rem;
  padding: 0.5rem 0.5rem 0.5rem 0;

  display: flex;
  flex-direction: column;
}
.title-faqs {
  position: relative;
  padding: 0.5em 0 0.5rem 3rem;
  width: 14rem;
  margin: 0 0 0 1rem;
}
.title-faqs::after {
  position: absolute;
  content: url("https://api.iconify.design/ep/arrow-right-bold.svg");
  -webkit-filter: brightness(0) saturate(100%) invert(31%) sepia(70%)
    saturate(2003%) hue-rotate(308deg) brightness(95%) contrast(87%);
  filter: brightness(0) saturate(100%) invert(31%) sepia(70%) saturate(2003%)
    hue-rotate(308deg) brightness(95%) contrast(87%);
  left: 1rem;
  transition: all 0.5s;
  cursor: pointer;
}
.group-active .title-faqs::after {
  transform: rotate(90deg);
  /* transition: all 0.5s; */
}
.answer-faqs {
  opacity: 0;
  height: 0;
  margin: 0 0 0 2rem;

  overflow: hidden;
  transition: all 0.5s;
}
.group-active .answer-faqs {
  opacity: 1;
  height: 100%;
  transition: all 0.5s;
  margin: 1rem 0 1rem 2rem;
  line-height: 1.6;
}
.type-faqs::after {
  position: absolute;
  content: url("https://api.iconify.design/ep/arrow-right-bold.svg");
  -webkit-filter: brightness(0) saturate(100%) invert(31%) sepia(70%)
    saturate(2003%) hue-rotate(308deg) brightness(95%) contrast(87%);
  filter: brightness(0) saturate(100%) invert(31%) sepia(70%) saturate(2003%)
    hue-rotate(308deg) brightness(95%) contrast(87%);
  left: -0rem;
  transition: all 0.5s;
  cursor: pointer;
}

.type-faqs {
  position: relative;
  padding: 0.5rem 0 0.5rem 2rem;
  margin: 0 0 0 1rem;
  cursor: pointer;
}
.type-active .type-faqs::after {
  transform: rotate(90deg);
}
.type-active .type-faq-content .title-faqs {
  opacity: 1;
  height: 100%;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
}

.type-faq-content .title-faqs {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}
.type-faq-content {
  min-height: 3rem;
  padding: 0 2rem 0 0;
}

.search-faqs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 2rem;
}
.faqsImg {
  display: none;
}
.search-bar-faqs {
  position: relative;
}
.faq-description {
  padding: 2rem 0;
}
.faq-description span {
  color: var(--fuchsia);
}
.faq-description h2 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  padding: 0.5rem 0;
}
.faqs-info h1 {
  color: #6b3378;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  border-bottom: 1px solid #6b3378;
}
.faqs-info h2 {
  color: #28132d;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}
.faqs-content {
  height: 100%;
}
.faqs-content .type-faqs {
  color: #6b3378;
  border-bottom: 1px solid #6b3378;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}
.faqs-content .title-faqs {
  color: var(--purpleLight);
  border-bottom: 1px solid var(--purpleLight);
}

.faqs-questions-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%;
  margin: 1rem 0 0 0;
  width: 100%;
}
@media (min-width: 800px) {
  .faqs-container {
    padding: 0 2rem 2rem 2rem;
  }
  .search-bar-faqs {
    width: 48%;
    position: relative;
  }
  .faqsImg {
    display: block;
    width: 42%;
  }
}
