.log-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  min-height: 20rem;
  background: var(--superLigth);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 400;
  overflow-y: auto;
  overflow-x: hidden;
}

.log-logo {
  display: none;
}

.title-login {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  margin: 0 0 1rem 0;
  color: #2e2e2ef0;
}
.log-content-container {
  display: flex;
  flex-direction: column;

  margin: 0 2.5rem;
}
.log-content-container > button {
  margin: 1rem 0 0 0;
  color: var(--secondaryColor);
}
.log-content-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.log-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.input-password {
  position: relative;
}
.show-password {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 12px;
  border: inherit;
  width: 3rem;
  height: 100%;

  text-align: center;
}
.show-password svg {
  width: 1.5rem;
  height: 100%;
  color: var(--secondaryColor);
}
.forgot-password {
  text-align: end;
}
.forgot-password a {
  color: #6b3378;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.error-singIn {
  color: red;
  display: flex;
  justify-content: center;
}
.button-log-container {
  margin: auto;
}
.singIn-singUp {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}
.singIn-singUp a {
  color: #e33d8c;
}
.singIn-singUp p {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #28132d;
}
.singIn-singUp button {
  background: none;
  border: none;
  color: #e33d8c;
  font-weight: 700;
  font-size: 16px;
}
.check-password-container {
  margin: 2rem 0 0 1rem;
}
.check-password-container div {
  display: flex;
  gap: 0.5rem;
}
.check-password-container svg {
  color: #b5bdc6;
  width: 12px;
  height: 12px;
}
.complete-password {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  margin: auto;
  color: #08c96c;
  height: 12px;
  width: 12px;
}
.check-password-true svg {
  color: #08c96c;
}
.check-password-container p {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.1px;
  color: #28132d;
}
.input-ok-check::after {
  content: "";
  position: absolute;
  top: 36%;
  bottom: 0;
  right: -20px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: auto;
  background-color: rgb(0, 255, 0);
}

.privacy-conditions {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  padding: 0 1rem;
}

.privacy-conditions .checkbox-container {
  height: 12px;
  width: 12px;
}
.privacy-conditions p {
  margin: 0;
}
.error-text-sign-up::after {
  top: -44px;
}

.oauthGoogle-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin: 1rem 0;
  padding: 0 0 1rem 0;
  width: 100%;
}
.separation-oauthGoogle > p {
  background-color: var(--defaultBackground);
  z-index: 1;
  margin: 0;
  font-size: 16px;
  position: relative;
  display: inline;
  padding: 0 0.5rem;
}
.separation-oauthGoogle {
  position: relative;
  width: 100%;
  text-align: center;
}
.separation-oauthGoogle::after {
  content: "";
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: var(--fuchsiaDisable);
}
.separation-oauthGoogle::after {
  left: 0;
  right: 0;
}

@media (min-width: 780px) {
  .log-logo {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(136deg, #0af5f0 7.21%, #6b3378 79.31%);
    -webkit-clip-path: circle(73.6% at 23% 18%);
    clip-path: circle(73.6% at 23% 18%);
    display: flex;
    align-items: center;
    border-radius: inherit;
    height: 42vw;
  }
  .log-logo img {
    position: absolute;
    top: 28%;
    left: 15%;
    height: 20%;
  }
}

.log-modal {
  position: relative;
  width: 100%;
  background-color: unset;
}
.log-modal .info-field {
  width: 100%;
}
.log-modal .info-input-container {
  width: 100%;
}
@media (min-width: 480px) {
  .log-modal .info-field {
    width: 300px;
  }
}
