:root {
  --backgroundTexture: url("https://www.transparenttextures.com/patterns/light-paper-fibers.png");
}

.postcard-container {
  -webkit-animation: 4s toBack forwards;
  animation: 4s toBack forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.wrap-original-transform {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

/* ----------------------------------------------- */
/* -------------------- FRONT -------------------- */
/* ----------------------------------------------- */

.postcard--front {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.postcard--front .postcard--size {
  background-image: var(--backgroundTexture);
}
.postcard--size {
  top: 0;
}

.postcard--front-stamps {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 5%;
  width: 100%;
  height: 20%;
  gap: 5%;
}

.postcard--stamp {
  width: 15%;
  height: 100%;
}

.postcard--front-message {
  margin: auto;
  width: 40%;
}

.postcard--front-message > p {
  text-align: center;
}

/* ----------------------------------------------- */
/* -------------------- BACK --------------------- */
/* ----------------------------------------------- */

.postcard--back {
  position: absolute;
  transform: rotateY(90deg);
  z-index: 0;
  background-color: #d0d0d0a2;
  background-image: var(--backgroundTexture);
  box-shadow: 0 0 80px grey;
  z-index: 4;
}

.postcard--back-laterals {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* z-index: 3; */
}
.postcard--back-laterals * {
  background-image: var(--backgroundTexture);
}

.postcard--back-lateral {
  position: absolute;
  box-shadow: 0 0 10px grey;
}

.postcard--back-lateral:nth-of-type(1) {
  width: 70%;
  height: 80%;
  top: 17%;
  left: -43%;
  border-radius: 81px;
  transform: rotate(-42deg);
  z-index: 4;
}

.postcard--back-lateral:nth-of-type(2) {
  width: 70%;
  height: 80%;
  top: 17%;
  right: -43%;
  border-radius: 81px;
  transform: rotate(42deg);
  z-index: 4;
}

.envelope-square .postcard--back-lateral:nth-of-type(1) {
  width: 64%;
  height: 86%;
  top: 7.4%;
  left: -33%;
  border-radius: 70px;
  transform: rotate(-34deg);
}

.envelope-square .postcard--back-lateral:nth-of-type(2) {
  width: 64%;
  height: 86%;
  top: 7.4%;
  right: -33%;
  border-radius: 70px;
  transform: rotate(34deg);
}

.postcard--back-bottom {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.postcard--back-bottom * {
  background-image: var(--backgroundTexture);
}

.postcard--back-bottom-element {
  position: absolute;
  margin: 0px auto;
  height: 52%;
  width: 41.5%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0px -38px 27px -41px grey;
}
.postcard--back-bottom-element::after {
  position: absolute;
  content: "";
  top: 5%;
  left: -50%;
  width: 312px;
  height: 455px;
  background-color: inherit;
  background-image: var(--backgroundTexture);
  z-index: -1;
  border-radius: 86px;
  transform: rotate(35deg);
  box-shadow: 0 0 10px grey;
}
.postcard--back-bottom-element::before {
  position: absolute;
  content: "";
  top: 5%;
  right: -50%;
  width: 312px;
  height: 455px;
  background-color: inherit;
  background-image: var(--backgroundTexture);
  z-index: -1;
  border-radius: 86px;
  transform: rotate(-35deg);
  box-shadow: 0 0 10px grey;
}
.envelope-square .postcard--back-bottom-element {
  width: 35%;
}
.envelope-square .postcard--back-bottom-element::after {
  top: 1%;
  left: -61%;
  width: 298px;
  height: 579px;
  transform: rotate(30deg);
}
.envelope-square .postcard--back-bottom-element::before {
  top: 1%;
  right: -61%;
  width: 298px;
  height: 579px;
  transform: rotate(-30deg);
}

.postcard--back-tongue-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 4;
  transform-origin: top;
  -webkit-animation: 2s toUp forwards;
  animation: 2s toUp forwards;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  top: 0;
}

.postcard--back-tongue {
  position: relative;
  border-radius: 0 0 150px 150px;
  margin: auto;
  width: 39%;
  height: 65%;
  box-shadow: 0px -25px 69px 25px grey;
  background-image: var(--backgroundTexture);
}
.postcard--back-tongue::after {
  position: absolute;
  content: "";
  top: -38%;
  left: 75%;
  margin: auto;
  background-color: inherit;
  transform: rotate(43deg);
  width: 73%;
  border-radius: 0 72px 0 0;
  height: 120%;
  box-shadow: 104px 30px 86px -98px grey;
  background-image: var(--backgroundTexture);
}
.postcard--back-tongue::before {
  position: absolute;
  content: "";
  top: -38%;
  right: 75%;
  margin: auto;
  background-color: inherit;
  transform: rotate(-43deg);
  width: 73%;
  border-radius: 72px 0 0 0;
  height: 120%;
  box-shadow: -104px 30px 86px -98px grey;
  background-image: var(--backgroundTexture);
}

.envelope-square .postcard--back-tongue {
  width: 39%;
  height: 65%;
}
.envelope-square .postcard--back-tongue::after {
  top: -22%;
  left: 72%;
  transform: rotate(32deg);
  width: 73%;
  height: 106%;
}
.envelope-square .postcard--back-tongue::before {
  top: -22%;
  right: 72%;
  transform: rotate(-32deg);
  width: 73%;
  height: 106%;
}
/* ----------------------------------------------- */
/* ------------------ POSTAL --------------------- */
/* ----------------------------------------------- */

.postcard--postal-container {
  position: absolute;

  display: flex;
  width: 100% !important;
  height: 100% !important;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 0;
  left: 0;
  z-index: 2;
  -webkit-animation: 4s toOut forwards;
  animation: 4s toOut forwards;
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  transition: all 0.6s;
}

.card-front {
  position: absolute;
  top: 0;
  z-index: 200;
  -webkit-animation: 4s disapear forwards;
  animation: 4s disapear forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.card-back {
  position: relative;
  transform: rotateY(180deg);
  z-index: 10;
}
.original-transform {
  -webkit-animation: envelopeRotate 2s forwards;
  animation: envelopeRotate 2s forwards;
  -webkit-animation-delay: 4s;
  animation-delay: 8s;
}
.postcard--postal--container--landscape {
  -webkit-animation: 4s toOutLandscape forwards;
  animation: 4s toOutLandscape forwards;
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

/* ----------------------------------------------- */
/* ---------------- ANIMATIONS ------------------- */
/* ----------------------------------------------- */
@-webkit-keyframes toBack {
  50% {
    transform: rotateX(3deg) scale3d(1.3, 1.3, 1) translateY(-25vh);
  }

  100% {
    transform: rotateX(0deg) rotateY(-180deg) scale3d(1.3, 1.3, 1)
      translateY(-20%);
  }
}

@keyframes toBack {
  50% {
    transform: rotateX(3deg) scale3d(0.4, 0.4, 1) translateY(-20vh);
  }

  100% {
    transform: rotateX(0deg) rotateY(-180deg) scale3d(0.4, 0.4, 1)
      translateY(0%);
  }
}
@-webkit-keyframes toUp {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
    z-index: 1;
  }
}

@keyframes toUp {
  0% {
    transform: rotateX(0deg);
  }
  29% {
    color: initial;
  }
  30% {
    color: transparent;
  }
  100% {
    transform: rotateX(180deg);
    color: transparent;
    z-index: 1;
  }
}

@-webkit-keyframes toOutLandscape {
  0% {
    z-index: 2;
  }

  30% {
    top: -105%;
    left: 0%;
    transform: rotateZ(-0deg);
    z-index: 5;
  }

  100% {
    top: -20%;
    left: -0%;
    z-index: 5;
    transform: rotate(-20deg);
  }
}

@keyframes toOutLandscape {
  0% {
    z-index: 2;
  }

  30% {
    top: -125%;
    left: 0%;
    transform: rotateZ(0deg);
    z-index: 2;
  }
  50% {
    z-index: 5;
  }

  100% {
    top: -20%;
    left: -10%;
    margin: auto;
    z-index: 5;
    transform: rotate(-20deg);
    box-shadow: 8px 9px 77px 0px #000000b0;
  }
}

@-webkit-keyframes toOut {
  0% {
    z-index: 2;
  }

  10% {
    z-index: 2;
  }
  30% {
    left: 0;
    top: -105%;
    transform: rotateZ(-90deg);
    z-index: 2;
    box-shadow: none;
  }

  50% {
    top: -105%;
    left: 0%;
    transform: rotateZ(-20deg);
    z-index: 5;
  }

  100% {
    top: -20%;
    left: 0%;
    z-index: 5;
    transform: rotate(-20deg);
  }
}

@keyframes toOut {
  0% {
    z-index: 2;
  }

  10% {
    z-index: 2;
  }
  30% {
    left: 0;
    top: -105%;
    transform: rotate(0deg);
    z-index: 2;
    box-shadow: none;
  }

  50% {
    margin: 0;
    top: -105%;
    left: 0%;
    transform: rotate(-110deg);
    z-index: 5;
  }

  100% {
    margin: -8rem 0 0 -3rem;
    z-index: 5;
    transform: rotate(-110deg);
    box-shadow: 8px 9px 77px 0px #000000b0;
  }
}

@-webkit-keyframes envelopeRotate {
  0% {
    transform: rotate(0deg) translateX(0%);
  }
  100% {
    transform: rotate(20deg) translateX(-14%);
  }
}

@keyframes envelopeRotate {
  0% {
    transform: rotate(0deg) translateX(0%);
  }
  100% {
    transform: rotate(-20deg) translateX(0%);
  }
}

@-webkit-keyframes postalRotate {
  100% {
    transform: rotate(-20deg);
  }
}

@keyframes postalRotate {
  100% {
    transform: rotate(-20deg);
  }
}

@-webkit-keyframes disapear {
  0% {
    z-index: 200;
    opacity: 1;
  }
  90% {
    z-index: 1;
    opacity: 1;
  }

  100% {
    z-index: -1;
    opacity: 0;
  }
}

@keyframes disapear {
  0% {
    z-index: 200;
    opacity: 1;
  }
  90% {
    z-index: 1;
    opacity: 1;
  }

  100% {
    z-index: -1;
    opacity: 0;
  }
}
@media (min-width: 1080px) {
  @-webkit-keyframes toBack {
    50% {
      transform: rotateX(3deg) scale3d(1.7, 1.7, 1) translateY(-0vh);
    }

    100% {
      transform: rotateX(0deg) rotateY(-180deg) translateY(40vh)
        scale3d(1.8, 12, 1);
    }
  }

  @keyframes toBack {
    50% {
      transform: rotateX(3deg) scale3d(0.7, 0.7, 1) translateY(0vh);
    }

    100% {
      transform: rotateX(0deg) rotateY(-180deg) translateY(40%)
        scale3d(0.7, 0.7, 1);
    }
  }

  @-webkit-keyframes envelopeRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(20deg);
    }
  }

  @keyframes envelopeRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-20deg);
    }
  }
  @-webkit-keyframes toOut {
    0% {
      z-index: 2;
    }

    30% {
      left: 0%;
      /* top: -110%; */
      transform: rotateZ(-90deg);
      z-index: 2;
      box-shadow: none;
    }

    50% {
      z-index: 5;
    }

    100% {
      top: -30%;
      z-index: 5;
      transform: rotate(-20deg);
      box-shadow: 8px 9px 77px 0px #000000b0;
    }
  }

  @keyframes toOut {
    0% {
      z-index: 2;
    }

    10% {
      z-index: 2;
    }
    30% {
      left: 0;
      top: -105%;
      transform: rotate(0deg);
      z-index: 2;
      box-shadow: none;
    }

    50% {
      margin: 0;
      top: -105%;
      left: 0%;
      transform: rotate(-110deg);
      z-index: 5;
    }

    100% {
      margin: -8rem 0 0 -3rem;
      z-index: 5;
      transform: rotate(-110deg);
      box-shadow: 8px 9px 77px 0px #000000b0;
    }
  }
}
.envelope-portraid {
  -webkit-animation: 4s toBackPortrai forwards;
  animation: 4s toBackPortrai forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes toBackPortrai {
  50% {
    transform: rotateX(3deg) scale3d(0.7, 0.7, 1) translateY(-10vh);
  }

  100% {
    transform: rotateX(0deg) rotateY(-180deg) translateY(20%)
      scale3d(0.7, 0.7, 1);
  }
}

@keyframes toBackPortrai {
  50% {
    transform: rotateX(3deg) scale3d(0.45, 0.45, 1) translateY(-10vh);
  }

  100% {
    transform: rotateX(0deg) rotateY(-180deg) translateY(0%)
      scale3d(0.45, 0.45, 1);
  }
}
@media (min-width: 1080px) {
  @-webkit-keyframes toBackPortrai {
    50% {
      transform: rotateX(3deg) scale3d(0.7, 0.7, 1) translateY(-10vh);
    }

    100% {
      transform: rotateX(0deg) rotateY(-180deg) translateY(20%)
        scale3d(0.7, 0.7, 1);
    }
  }

  @keyframes toBackPortrai {
    50% {
      transform: rotateX(3deg) scale3d(0.7, 0.7, 1) translateY(0vh);
    }

    100% {
      transform: rotateX(0deg) rotateY(-180deg) translateY(25%)
        scale3d(0.7, 0.7, 1);
    }
  }
}
.envelope-square {
  -webkit-animation: 4s toBackSquare forwards;
  animation: 4s toBackSquare forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-webkit-keyframes toBackSquare {
  50% {
    transform: rotateX(3deg) scale3d(0.4, 0.4, 1) translateY(-10vh);
  }

  100% {
    transform: rotateX(0deg) rotateY(180deg) scale3d(0.4, 0.4, 1)
      translateY(0vh);
  }
}
@keyframes toBackSquare {
  50% {
    transform: rotateX(3deg) scale3d(0.4, 0.4, 1) translateY(-10vh);
  }

  100% {
    transform: rotateX(0deg) rotateY(180deg) scale3d(0.4, 0.4, 1)
      translateY(-25vh);
  }
}
@media (min-width: 1080px) {
  @-webkit-keyframes toBackSquare {
    50% {
      transform: rotateX(3deg) scale3d(0.5, 0.5, 1) translateY(-10vh);
    }

    100% {
      transform: rotateX(0deg) rotateY(180deg) scale3d(0.5, 0.5, 1)
        translateY(0vh);
    }
  }

  @keyframes toBackSquare {
    50% {
      transform: rotateX(3deg) scale3d(0.5, 0.5, 1) translateY(-10vh);
    }

    100% {
      transform: rotateX(0deg) rotateY(180deg) scale3d(0.5, 0.5, 1)
        translateY(0vh);
    }
  }
}
@media (min-width: 1080px) {
  .postcard--postal-container {
    cursor: zoom-in;
  }
  .postcard--postal-container-prueba:hover {
    cursor: zoom-out;
  }
  .postcard--postal-container-prueba:hover {
    transition: all 0.6s;
    translate: -0vh -0vh;
    scale: 1.4;
  }
}

@media (max-width: 1080px) {
  .envelope-landscape {
    /* margin-right: calc(100% - 570px); */
    /* margin-right: -webkit-calc(100% - 570px); */
    width: 100%;
  }
}
