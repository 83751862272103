.events-dashboard-container {
  background-color: var(--defaultBackground);
  box-shadow: 2px 2px 8px #0707073d;
  position: relative;
  border-radius: 12px 12px 0 0;
  padding: 4rem 1rem 2rem 1rem;
  display: flex;
  height: 95vh;
  min-height: 1000px;
  gap: 2rem;
}

.events-dashboard-container a {
  text-decoration: none;
}
.events-dashboard-body {
  height: 84%;
}
.events-dashboard {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
}
.events-dashboard-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #864096;
}
.events-dashboard-header h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #6b3378;
}
.events-dashboard-buttons {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;

  justify-content: space-between;
  gap: 1rem;
}

@media (min-width: 800px) {
  .events-dashboard-container {
    padding: 2rem;
  }
  .events-dashboard-buttons {
    justify-content: center;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }
  .events-dashboard-body {
    height: 85%;
  }
}

@media (min-width: 1328px) {
  .events-dashboard-header > h1 {
    text-align: center;
    grid-column: 2;
  }
  .events-dashboard-buttons {
    align-self: center;
    text-align: end;
    grid-column: 3;
    flex-wrap: nowrap;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }
}
.events-dashboard-body {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.events-dashboard-filters {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #0d060f;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  -moz-column-gap: 1rem;
  justify-items: center;
  column-gap: 1rem;
}

.events-dashboard-filter {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.events-dashboard-filter:hover::after,
.events-dashboard-filter-active::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: -2px;
  right: -4px;
  height: 4px;
  background-color: #e33d8c;
}
.events-dashboard-filter:hover,
.events-dashboard-filter-active {
  font-weight: 700;
  color: #6b3378;
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.1s;
}

/* invitation */

.invitation-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  overflow-y: auto;
  height: 90%;
  padding: 2rem 2rem 0 0;
}

.invitation-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 100%;
  margin: 0 auto;
}
.invitation-info h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 29px;
  margin: 0;
}
.invitation-info-preview {
  margin: auto;
}
.invitation-info-updated {
  margin: 1rem 0 1rem 1rem;
}

.invitation-info-date-draw {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: auto;
}
.invitation-info-date-draw p {
  position: absolute;
}

.invitation-info-month-draw {
  top: 12%;
  left: 0;
  right: 0;
  color: #fcfcfc;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
}
.invitation-info-string-day-draw {
  bottom: 26px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 41px;
  color: #0d060f;
  margin: 0;
}
.invitation-info-day-draw {
  bottom: 14px;
  right: 0;
  left: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #6b3378;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
}

.invitation-info-other-data {
  border-bottom: 1px solid #6b3378;
}
.invitation-info-other-data p {
  font-weight: 400;
  font-size: 16px;
  margin: 0.6rem 0;
}

.invitation-info-other-items {
  display: flex;
  align-items: center;

  gap: 2rem;
}
.invitation-info-other-items img {
  margin: 8px 0;
}

.invitation-info-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.invitation-info-edit svg {
  cursor: pointer;
}
.invitation-edit-button {
  font-weight: 700;
}
.invitation-edit-text {
  font-weight: 700;
  margin: 0;
  color: #6b3378;
}

.invitation-container-json {
  max-width: 200px;
  height: 300px;
  display: flex;
  align-items: center;
  margin: 8px 0;
}

@media (min-width: 800px) {
  .events-dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
  }
}
@media (min-width: 1360px) {
  .invitation-info-container {
    height: 100%;
    /* flex-wrap: nowrap;
    justify-content: space-between; */
  }
}
.more-options-container {
  background-color: #fcfcfc;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 1px rgba(51, 51, 51, 0.15),
    0px 1px 2px rgba(51, 51, 51, 0.3);
}

.modal-max-draft {
  min-height: 10rem;
  text-align: center;
}
.modal-max-draft svg {
  height: 5rem;
  width: 5rem;
  color: #f5c10a;
}
.modal-max-draft p {
  font-weight: 700;
  max-width: 15rem;
  margin: 2rem 0;
}
.modal-max-draft-error svg {
  height: 5rem;
  width: 5rem;
  color: #f50a0a;
}

.invitation-preview {
  width: 100%;
  box-shadow: 0 0 10px grey;
  height: 100%;
  border-radius: inherit;
}
