.side-panel-search-wrapper {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: relative;
}

.side-panel-search {
  width: 100%;
  height: 35px;
  background: #f8f8f8;
  border: 1px solid #c7bddc;
  border-radius: 12px;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  padding-left: 2.5rem;
  font-family: "Nunito";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #b06bbf;
}

.side-panel-search:focus {
  outline: none;
}

.side-panel-search::-webkit-input-placeholder {
  color: #b06bbf;
}

.side-panel-search::-moz-placeholder {
  color: #b06bbf;
}

.side-panel-search:-ms-input-placeholder {
  color: #b06bbf;
}

.side-panel-search::-ms-input-placeholder {
  color: #b06bbf;
}

.side-panel-search::placeholder {
  color: #b06bbf;
}

.input-icon {
  position: absolute;
  top: 22px;
  left: 1rem;
  font-size: 16px;
}
.polotno-side-panel {
  border-radius: 4px 0 0 0;
  min-height: 4rem;
  bottom: 0%;
}

.polotno-side-panel > div {
  border-radius: 4px 0 0 0;
}

.design-steps-second-section {
  display: flex;
}

.design-steps-wrapper {
  justify-content: center;
  grid-area: design-steps-wrapper;
}

.save-design-button-wrapper {
  justify-self: flex-end;
  grid-area: save-design-button-wrapper;
}
#polotno-app-container > div {
  width: 100%;
}
#polotno-app-container {
  overflow: hidden;
}

.side_panel_polotno_img:hover {
  transition: transform 0.4s;
  transform: scale(1.08);
}
.side_panel_polotno_credit--remarks {
  color: var(--textPurple);
  font-weight: 700;
}
.bp5-navbar.polotno-panel-container {
  bottom: 100%;

  box-shadow: none;
}
@media (min-width: 480px) {
  .design-steps-second-section {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 25px 25px;
    grid-auto-flow: row;
    grid-template-areas: ". design-steps-wrapper save-design-button-wrapper";
  }
  #polotno-app-container > div {
    width: auto;
  }

  .bp5-navbar.polotno-panel-container {
    bottom: 0%;
  }
}
