.verifiyingAccount__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.verifiyingAccount__title {
  font-size: 20px;
  font-weight: 600;
}
