.toggle-container {
  position: relative;
  min-width: 56px;
  width: 56px;
  height: 32px;
}
.toggle-label {
  position: absolute;
  left: 0;
  border: 4px solid #8ba6c4;
  width: 100%;
  height: 100%;
  background-color: #8ba6c4;
  border-radius: 19px;
  /* transition: all 0.3s; */
}
.toggle-input {
  display: none;
}

.toggle-label:before {
  content: "";
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 100%;
  background-color: #c5d3e2;
  transform: translateX(0%);
  transition: transform 50ms;
}
.toggle-input:checked + .toggle-label:before,
.toggle-input-checked + .toggle-label:before {
  background-image: url("../img/checkIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5b6d4;
  transform: translateX(100%);
  /* transition: transform 0.3s; */
}
.toggle-input:checked + .toggle-label,
.toggle-input-checked + .toggle-label {
  background-color: #e33d8c;
  border: 4px solid #e33d8c;
  transition: all 50ms;
}

.toggle-input:checked + .toggle-label.purple-toggle:before {
  background-image: url("../img/purpleCheckIcon.svg");
  background-color: #dfc4e6;
}
.toggle-input:checked + .toggle-label.purple-toggle {
  background-color: #6b3378;
  border: 4px solid #6b3378;
}

.toggle-text-container {
  position: relative;
  width: 166px;
  height: 32px;
}
.toggle-text {
  position: absolute;
  left: 0;
  border: 2px solid #6b3378;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  border-radius: 19px;
  /* transition: all 0.3s; */
}
.disabled.toggle-text {
  border: 2px solid #8ba6c4;
  background-color: #8ba6c4;
}
.toggle-text:before {
  content: attr(label-content-front);
  position: absolute;
  left: 0;
  width: 81px;
  height: 26px;
  margin-top: 0.075rem;
  margin-left: 0.1rem;
  border-radius: 16px;
  background-color: #6b3378;
  transform: translateX(0%);
  /* transition: transform 0.3s; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: #fcfcfc;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  gap: 10px;
}
.disabled.toggle-text:before {
  background-color: #c5d3e2;
}
.toggle-input:checked + .toggle-text:before {
  content: attr(label-content-back);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #6b3378;
  transform: translateX(100%);
  /* transition: transform 0.5s; */
}
.toggle-input:checked + .toggle-text {
  background-color: #fcfcfc;
  border: 2px solid #6b3378;
  /* transition: all 0.3s; */
}
