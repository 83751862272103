@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;900&display=swap");

:root {
  --fuchsia: #e33d8c;
  --fuchsiaHover: #fce9f2;
  --fuchsiaDisable: #f09dc4;
  --purple: #43204b;
  --purpleLight: #b06bbf;
  --purpleHover: #f6edf7;
  --purpleDisable: #d5b0de;
  --textPurple: #43204b;
  --defaultBackground: #ffffff;
  --turquoise: #0af5f0;
  --turquoiseDark19: #045c5a;
  --turquoiseLight88: #c3fcfb;
  --turquoiseLight94: #e0fefd;
  --turquoiseLight75: #85faf8;
  --blueLight66: #8ba6c4;
  --greyHover: #e6e9ec;
  /* new variables */
  --primaryColor: #43204b;
  --background: linear-gradient(180deg, #6b3378 0%, #0af5f0 100%);
  --text: #28132d;
  --header: #43204b;
  --secondText: #ffffff;
  --superLigth: #ffffff;
  --superDark: #000000;
  --floatLaboratory: #43204b;
  --secondLigth: #ee86b7;
  --secondaryColor: #e33d8c;
  --greyHover: #e6e9ec;
  --white: var(--secondText);
}
* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}
body *::-webkit-scrollbar {
  width: 0.7rem;
}

body *::-webkit-scrollbar-track {
  background-color: #ebebeb;

  border-radius: 8px;
}

body *::-webkit-scrollbar-thumb {
  background-color: var(--purpleDisable);
  border-radius: 8px;
  width: 0.5rem;
}
body {
  background: var(--background);
  min-height: 100vh;
  margin: 0;
}

/* .events-dashboard-container {
  zoom: 0.5;
  -ms-zoom: 0.5;
} */

@media (min-width: 750px) {
  .events-dashboard-container,
  .creation-board-container {
    zoom: 100%;
  }
}
#root {
  min-height: 100vh;
  /* display: grid;
  grid-template-rows: 148px auto 167px; */
}

.container {
  width: 95%;
  margin: 0 auto;
}

@media (min-width: 1080px) {
  .container {
    width: 85%;
  }
}
@media (min-width: 1441px) {
  .container {
    width: 85%;
  }
}

@media (min-width: 1441px) {
  .container {
    width: 70%;
    max-width: 1800px;
  }
}

button:focus {
  border-style: unset;
  outline: unset;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}
a:focus {
  outline: unset;
}

select:disabled {
  color: unset;
  opacity: 1;
  border-color: rgba(118, 118, 118, 0.3);
}
input[type]:disabled {
  border-color: rgba(118, 118, 118, 0.3);
  outline-color: rgba(118, 118, 118, 0.3);
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.visibility-hidden {
  visibility: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

.captcha {
  position: relative;
  color: #b1b1b1;
  font-size: 12px;
  max-width: 20rem;
}

.input-required {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.input-required::after {
  position: absolute;
  content: "*";
  right: -1rem;
  color: red;
  font-size: 18px;
}
