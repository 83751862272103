.navbar-container {
  min-width: 8rem;
  width: 8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #c088cc;
  border-radius: 16px;
  background-color: #ffffff;
  z-index: 300;
  transition: min-width 0.5s;
  padding-bottom: 0.5rem;
  margin: 87px 0 0 0;
  max-width: 239px;
}

.extended {
  min-width: 15rem;
  width: 15rem;
  transition: all 0.5s;
}
.navbar {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.navbar-container-movil .navbar-img-profile p {
  font-weight: 700;
  font-size: 32px;
  margin: 0;
}
@media (min-width: 480px) {
  .navbar {
    height: 90%;
  }
}

.navbar-item {
  position: relative;
  height: 2.5rem;
  padding: 0 0 0 4rem;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

.navbar-item path {
  fill: #e33d8c;
}

.navbar-item::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  z-index: -100;
  border-radius: 0px 16px 16px 0px;
  transition: right 0.5s;
}
.navbar-item:hover::after,
.navbar-item-active::after {
  background-color: #b4a7d1;
  border-radius: 0px 16px 16px 0px;
  right: 20px;
  transition: right 0.5s;
}
.navbar-item:hover path,
.navbar-item-active path {
  fill: #6b3378;
}
.navbar-item svg {
  position: absolute;
  left: 40%;
  transition: all 0.5s;
  font-size: 20px;
}
.extended svg {
  margin: 0;
  left: 12%;
  transition: all 0.5s;
}

.navbar-img-profile {
  margin: -2.5rem auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #6b3378;
  -o-object-position: center;
  object-position: center;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.navbar-text {
  opacity: 0;
  transition-property: all;
  transition-duration: 0.1s;
  margin: 0;
}
.extended .navbar-text {
  opacity: 1;
  transition-delay: 0.2s;
  transition-property: all;
  transition-duration: 0.5s;
}

.button-open {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
  cursor: pointer;
}
.extended .button-open {
  justify-content: flex-start;
  transform: rotate(180deg);
}
.navbar-username {
  color: #6b3378;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
  margin: 0.5rem 0 0 0;
  padding: 0 3px;
  transition: font-size 0.5s;
}

.navbar-username::first-letter {
  text-transform: uppercase;
}

.extended .navbar-username {
  font-size: 24px;
  transition: font-size 0.5s;
}
.button-add {
  padding: 0;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
}
.button-add:active {
  transform: scale(0.98);
}
.button-add::after {
  background-color: #e33d8c;
  border-radius: 0px 16px 16px 0px;
  right: 20px;
  transition: all 0.5s;
}
.button-add .navbar-text {
  padding: 0 0 0 0.5rem;
  font-weight: 700;
}
.extended .button-add::after {
  background-color: #e33d8c;
  border-radius: 16px;
  left: 10%;
  right: 35px;
  transition: all 0.5s;
}

.extended .button-add svg {
  left: 20%;
  font-size: 20px;
}

.button-add path,
.button-add:hover path {
  fill: #ffffff;
}
.extended .button-logout {
  left: -10%;
}
.extended .button-logout::after {
  left: 10%;
}
@media (max-width: 800px) {
  .navbar-container-movil {
    border: none;
    position: absolute;
    background-color: #ffffff00;
    margin: 0;
    transition: all 0.5s;
    height: 0;
    top: 3.5rem;
    box-shadow: 13px 7px 67px -32px rgba(0, 0, 0, 0.5);
  }

  .navbar-container-movil .button-open {
    display: none;
  }
  .navbar-container-movil .navbar-username {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
  .navbar-container-movil .navbar {
    opacity: 0;
    visibility: hidden;
  }
  .extended {
    background-color: var(--white);
    height: 60%;

    border: 1px solid #c088cc;
  }
  .extended .navbar {
    opacity: 1;
    visibility: visible;
    transition: all 1s;
  }
  .extended .navbar-username {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
  .navbar-img-profile {
    display: flex;
    flex-direction: column;
    height: 3rem;
    width: 3rem;
  }

  .extended {
    min-width: 15rem;
    width: 15rem;
    transition: all 0.5s;
  }

  .extended .button-nav-bar::after {
    transform: rotate(45deg);
    top: 45%;
    transition: all 0.5s;
  }

  .extended .button-nav-bar::before {
    transform: rotate(-45deg);
    bottom: 45%;
    transition: all 0.5s;
  }
  .navbar-img-profile {
    margin: -2.5rem 0 0 0;
  }
}
