.polls-container {
  padding: 1rem;
}
.save-button-poll {
  position: absolute;
  top: -2.7rem;
  right: 0rem;
  font-weight: 400;
  color: var(--white);
}
.poll-header {
  text-align: center;
}
.poll-header > h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: var(--textPurple);
}
.poll-header > p {
  color: var(--purpleLight);
}
.poll-creation-container {
  display: grid;
  grid-template-columns: auto;
}
.poll-options-container {
  margin: 0 3rem 0 1rem;
}
.poll-options-container > h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--textPurple);
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid #9ca7b3;
}
.poll-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 0 0 0.8rem;
}
/* .poll-options div:last-child {
  display: none;
} */

.poll-option {
  display: flex;
  gap: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--textPurple);
  cursor: pointer;
}
.poll-option svg {
  font-size: 20px;
  color: #364f6b;
}
@media (min-width: 720px) {
  .poll-creation-container {
    display: grid;
    grid-template-columns: auto 63%;
  }
  .save-button-poll {
    right: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .poll-creation-container {
    display: grid;
    grid-template-columns: 23% 63%;
  }
}
.questions-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  height: 100%;
  width: 98%;
  padding: 1rem 0;
}
.question-container {
  display: grid;
  grid-template-columns: 95% 5%;
  gap: 0.5rem;
  height: 100%;
  border-radius: 8px;
}
.question {
  border: 1px solid #c3fcfb;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.question p {
  margin: 0;
}
.type-question {
  text-align: center;
  margin: 0 0 1.5rem 0;
}
.number-question {
  color: var(--purple);
  font-size: 24px;
  font-weight: 700;
}
.checkbox-options {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 1rem;
  gap: 2rem;
}
.checkbox-option {
  display: flex;
  align-items: center;

  gap: 0.5rem;
}
.checkbox-options label {
  font-size: 10px;
}
.question-input-container {
  display: flex;
  width: 250px;
}
.down-up-icons-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.actions-poll-icon {
  font-size: 20px;
  color: var(--purple);
}
.add-response {
  color: var(--purple);
  font-weight: 700;
  letter-spacing: 0.1px;
  margin: 0;
}
.question .add-response {
  color: var(--purple);
  font-weight: 700;
  letter-spacing: 0.1px;
  margin: 0;
  text-align: start;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.add-response svg {
  font-size: 22px;
  font-weight: 900;
}
.response-choice-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.response-choice {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 0 0 1.5rem;
}
.response-choice-plaseHolder {
  position: absolute;
  font-size: 10px;
  left: 11px;
  top: 4px;
  color: var(--purpleLight);
}
.response-choice-input-container {
  position: relative;
}
.response-choice-input {
  padding: 23px 0 0 11px;
  width: 214px;
  height: 49px;
  background-color: #fef6fa;
  color: var(--purple);
}
.response-choice-input:disabled {
  box-shadow: none;
}
.response-choice-actions {
  font-size: 18px;
  color: var(--purple);
}

.moda-delete-question-container {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 2rem;
  gap: 0.5rem;

  height: 11rem;
}
.moda-delete-question-container .table-group-delete-modal-title {
  margin: 1rem 0 0 0;
}
.moda-delete-question-container .table-group-delete-buttons {
  display: flex;
  align-self: flex-end;
}
.table-group-delete-modal-body {
  position: relative;
  width: 80%;
}
.table-group-delete-modal-body::after {
  content: url("https://api.iconify.design/fluent/error-circle-20-filled.svg?color=%23f5c10a&width=24&height=24");
  position: absolute;
  top: -1rem;
  left: -2rem;
}
@media (min-width: 480px) {
  .add-response {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 0 0 2rem;
  }
  .response-choice-input {
    width: 248px;
  }
}
