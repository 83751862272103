.checkbox-container {
  position: relative;
  width: 16px;
  height: 16px;
}
.checkbox-input {
  /* visibility: hidden; */
  opacity: 0;
}
.checkbox-label {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #9ca7b3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.checkbox-label svg {
  display: none;
}

.checkbox-input:checked + .checkbox-label svg {
  display: block;
  color: var(--white);
}
.checkbox-input:checked + .checkbox-label {
  background-color: var(--fuchsia);
  border: 2px solid var(--fuchsia);
}
