.paymentcanceled__main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.paymentcanceled__draw,
.paymentcanceled__body {
  width: 50%;
  height: 100%;
}

.paymentcanceled__draw {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url("../img/draws/FondoNido.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.paymentcanceled__draw-nest {
  background-image: url("../img/draws/Nido.png");
  width: 400px;
  height: 80%;
  background-size: contain;
  background-repeat: no-repeat;
}

.paymentcanceled__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentcanceled__body-text {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 10px;
  margin-bottom: 40px;
}

.paymentcanceled__text {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.paymentcanceled__text:first-of-type {
  font-size: 24px;
  color: var(--purple);
  font-weight: 700;
}

@media (max-width: 740px) {
  .paymentcanceled__draw {
    display: none;
  }

  .paymentcanceled__body {
    width: 100%;
  }
}
