.buy-invitations__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buy-invitations__title,
.buy-invitations__subtitle {
  margin: 2rem auto;
  font-size: 60px;
  color: var(--purple);
  text-align: center;
  font-weight: 600;
}
.buy-invitations__text {
  max-width: 900px;
  font-size: 18px;
}
.buy-invitations__subtitle {
  margin: 5rem auto 1rem auto;
  font-size: 24px;
  font-weight: 200;
}

.buy-invitations__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--defaultBackground);
  min-height: 50vh;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding-bottom: 24px;
  padding: 0 1rem;
}

/* CONTENEDOR EXTERNO DEL SELECT */
.buy-invitations__select-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
  overflow: hidden;
  position: relative;
  border: 3px solid var(--purple);
  border-right: none;
  border-radius: 10px;
}

/* CONTENEDOR INTERNO DEL SELECT */
.buy-invitations__select-body {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 10px;
}

.buy-invitations__select-body:focus {
  outline: none;
}

/* MAQUETACION NUEVO BOTON DEL SELECT */
.buy-invitations__select-container::after {
  content: "\025be";
  display: table-cell;
  text-align: center;
  padding-top: 10px;
  color: var(--white);
  font-size: 30px;
  width: 70px;
  height: 100%;
  background-color: var(--purple);
  position: absolute;
  top: 0px;
  right: -5px;
  pointer-events: none;
}

/* OPTIONS DEL SELECT */
.buy-invitations__select-option {
  position: relative;
  margin-left: -15px;
  padding: 20px;
  width: 100%;
  font-size: 20px;
  border-radius: 0px;
}

.buy-invitations__select-option::-moz-selection {
  background: red;
}

.buy-invitations__select-option::selection {
  background: red;
}

@media (max-width: 750px) {
  .buy-invitations__select-container {
    width: 90%;
  }
}
