.paymentsuccess__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentsuccess__frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
}

.paymentsuccess__frame-photo {
  width: 100%;
  height: auto;
  display: block;
}

.paymentsuccess__title,
.paymentsuccess__subtitle {
  text-align: center;
}

.paymentsuccess__title {
  font-size: 24px;
  color: var(--purple);
  font-weight: 600;
}

.paymentsuccess__subtitle {
  font-size: 20px;
  font-weight: 400;
}
