.campform__main {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
}

.campform__text {
  font-size: 16px;
  line-height: 21.82px;
  letter-spacing: 0.1px;
}

.campform__campvalue {
  font-weight: 700;
  color: var(--purple);
  margin: 0 1rem 0 0;
}

.campform__defaultvalue {
  font-weight: 400;
  color: var(--text);
}
.campform__input {
  height: 48px;
  width: 170px;
}
