.contact-us-header {
  text-align: left;
  /* color: var(--primaryColor); */
}

.contact-us-header h1,
.contact-us-header p {
  margin: 0;
  font-size: 14px;
  color: #6b3378;
}

.info-input-container-contact {
  width: unset;
}
.spanblock {
  display: block;
}
/* .contact-us-container {
  background-color: unset;
} */
.contact-us {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1vh 0vw 5vh 0vw;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  gap: 1.5rem;
  background-color: #fcfcfc;
  min-height: 472px;
  justify-content: center;
  width: 60%;
  padding: 3vh 2rem;
  border-radius: 0 15px 15px 0;
}

.contact-us-form .primary-button {
  align-self: center;
}
.contact-us-form-textarea {
  height: 100px;
}
.contact-us-form textarea {
  resize: none;
}

.contact-us-form p {
  text-align: center;
  color: #6b3378;
  font-weight: bold;
}
.contact-us-header-subtitle {
  color: var(--primaryColor);

  font-weight: 300;
  font-size: 0.8rem;
  margin: 0;
}

.contact-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(252, 252, 252, 0.5);
  border-radius: 15px 0 0 15px;
  width: 40%;
  padding: 3vh 2rem;
}
.contact-info p {
  justify-self: flex-start;
  color: #6b3378;
  font-weight: bold;
}

.contact-kolipict {
  width: 60%;
  max-height: 50%;
}

.contact-social-media {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
}
.contact-email {
  display: flex;
  align-items: center;
  color: #6b3378;
}
@media screen and (max-width: 720px) {
  .contact-us {
    flex-direction: column-reverse;
  }
  .contact-us-header {
    text-align: center;
  }
  .contact-us-form {
    border-radius: 15px 15px 0 0;
    width: 100%;
  }
  .contact-social-media {
    width: 100%;
  }
  .contact-social-media a {
    font-size: 50px;
  }

  .contact-info {
    border-radius: 0 0 15px 15px;
    width: 100%;
    gap: 1.5rem;
    justify-content: space-evenly;
  }
}

.contact-us-form-send {
  display: flex;
  flex-direction: column;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  gap: 1.5rem;
  background-color: #fcfcfc;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3vh 2rem;
  border-radius: 15px;
  margin: 0 0 5rem 0;
  text-align: center;
}
.contact-us-form-send img {
  /* width: 14rem; */
  max-width: 350px;
}
.contact-us-form-send h2 {
  color: var(--purple);
  font-weight: 700;
  font-size: 38px;
  line-height: 25px;
}
.contact-us-form-send > p {
  font-weight: 400;
  font-size: 22px;
  line-height: 44px;
}
.contact-us-form-send-social-media {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.contact-us-form-send-social-media svg {
  height: 2rem;
  width: 2rem;
  margin: 0 0.5rem 0 0.5rem;
}
.contact-us-form-send-social-media > p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
@media (min-width: 480px) {
  .contact-us-header h1,
  .contact-us-header p {
    font-size: 16px;
  }
}
