.save-buttons-container {
  position: relative;
  overflow: hidden;
  height: 1.5rem;
  width: 8rem;
}
.save-button-container * {
  color: var(--floatLaboratory);
}
.save-button-container {
  position: absolute;
  width: 100%;
}
.save-button {
  margin: auto;
}
.save-button-container:hover * {
  color: var(--secondaryColor);
}
.loading {
  display: none;
  text-align: center;
}

.save,
.error-save {
  z-index: 1;
  bottom: 5rem;
  transition: all 1s;
}
.save-active {
  bottom: 0.2rem;
  display: block;
}
.save-button-original {
  visibility: hidden;
  top: 4rem;
}
.prosesing {
  transition-delay: 0.5s;
  transition: all 1s;
  visibility: visible;
  top: 0;
}
.save path {
  color: var(--secondText);
}
.error-save path {
  color: red;
}
.save-container-two {
  height: 3.5rem;
  width: 10rem;
  text-align: center;
}
.save-container-two * {
  color: var(--secondText);
}
.save-container-two:hover * {
  color: var(--secondText);
}
