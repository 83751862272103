.pages-control {
  background: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 3.7rem;
  right: 0.8%;
  z-index: 10;
}

.pages-control > .pages-control-toggle-text-container {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 9px;
}

.design-pages-toggle-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 8px;
  letter-spacing: 0.1px;

  color: #6b3378;
}
.pages-control-toggle-text {
  font-size: 30px;

  display: flex;
  gap: 1rem;
  padding: 0.2rem 1rem;
  align-items: center;
  border-radius: 9px;
  cursor: pointer;
}
.pages-control-toggle-text img {
  width: 2rem;
  fill: wheat;
  color: wheat;
}
.toggle-active img {
  -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
    saturate(0%) hue-rotate(94deg) brightness(109%) contrast(102%);
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(94deg) brightness(109%) contrast(102%);
}

.toggle-active {
  background-color: var(--purple);
  color: rgb(255, 255, 255);
  fill: wheat;
}
