.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgba(27, 27, 27, 0.479);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(2px);
  z-index: 1000;
  display: flex;
}
.modal {
  position: relative;
  margin: auto;
  min-height: 200px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 8px;
  z-index: 3000;
  padding: 1.5rem;
  background-color: #fcfcfc;
  overflow-wrap: break-word;
  max-width: 95vw;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
}
.button-closed-modal {
  color: #6b3378;
  min-height: 25px;
  width: 25px;
  align-self: flex-end;
}
.button-closed-modal:hover {
  transform: scale(1.3);
}
