.error-404-box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* height: 100vh; */
  /* width: 100vw; */
  padding: 2rem;
  align-content: space-between;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #e5e5e5;
  z-index: 500;
  color: #069996;
}

.error-404-header {
  display: flex;
  justify-content: space-between;
}
.error-404-header img {
  max-width: 50%;
}
.error-404-header button {
  border: none;
  background-color: transparent;
}
.error-404-header svg {
  color: #e33d8c;
}
.error-404-title img {
  max-height: 80%;
}
.error-404-title {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error-404-title p {
  font-weight: 400;
  font-size: 5vh;
  margin: 0;
}
.error-404-title h1 {
  font-weight: 700;
  font-size: 10vh;
  line-height: 98px;
  margin: 0;
}
.error-404-redirect {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.error-404-redirect a {
  color: #e33d8c;
}
