.invitation-page-container {
  top: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 450;
  max-width: 100vw;
  overflow: hidden;

  background-image: url("../img/hand-painted-watercolor-background.webp");
}
.preview-animation-envelope {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo-invitation-page {
  background-color: white;
  width: 100%;

  display: flex;

  z-index: 200;
}
.logo-invitation-page .logo {
  margin-bottom: auto;
  margin: auto;
  max-width: 11rem;
}
.invitation-page-content {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;

  height: 100%;
}

.invitation-page-container .postcard--envelope {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -10%;
  max-width: 400px;
  padding-bottom: 0;
}
.invitation-button-confirm-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 2rem 0 0 0;
  padding: 30px 26px;
  background: var(--defaultBackground);
  box-shadow: 0px 1.13994px 3.41981px 1.13994px rgba(51, 51, 51, 0.15),
    0px 1.13994px 2.27988px rgba(51, 51, 51, 0.3);
  border-radius: 18.239px;
  z-index: 1;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;

  right: 0%;
  left: 0;
  bottom: 1%;
  opacity: 0;
  -webkit-animation: 2.5s confirmOpacity forwards;
  animation: 2.5s confirmOpacity forwards;
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}
@-webkit-keyframes confirmOpacity {
  100% {
    opacity: 1;
  }
}
@keyframes confirmOpacity {
  100% {
    opacity: 1;
  }
}
.invitation-button-confirm-container button {
  margin: 0;
  padding: 0 3.5rem;
}
.invitation-msg {
  color: var(--purple);
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.invitation-msg--warning {
  color: var(--purple);
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.invitation-questionary-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90vw;
  padding: 0.6rem;
  overflow: auto;
}

.invitation-questionary-container > h3 {
  font-weight: 700;
  font-size: 32px;
  margin: 0 0 2rem 0;
}
.invitation-questionary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}
.invitation-questionary > p {
  margin: 1.5rem 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.1px;
  color: var(--text);
}
.invitation-questions {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 2rem 0 0 0;
}
.invitation-questionary-inputs {
  display: flex;
  gap: 1rem;
}
.invitation-questionary-input-container > p {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #031c5e;
}
.invitation-questionary-input-container > input {
  display: none;
}

.invitation-questionary-input-container label {
  color: var(--purple);
  border-color: var(--purple);
}
.invitation-questionary-input-container svg {
  color: var(--purple);
  font-size: 15px;
}
.invitation-questionary-input-container > input:checked + label {
  color: var(--defaultBackground);
  background-color: var(--purple);
}
.invitation-questionary-input-container > input:checked + label svg {
  color: var(--defaultBackground);
}

.invitation-question {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.invitation-question > p {
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;
}
.invitation-question > span {
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
}
.invitation-question > div {
  margin: 0;
}
.invitation-question label {
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;

  display: flex;
  align-items: center;
}
.invitation-question-checkbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.invitation-question-numbers {
  width: 4rem;
  height: 48px;
}
.invitation-question-textarea {
  height: 8rem;
}
.input[type="datet"]::-webkit-calendar-picker-indicator {
  position: relative;
}

.input[type="date"]::-webkit-calendar-picker-indicator {
  content: "";
  height: 60%;
  width: 2rem;
  position: absolute;
  right: 2%;
  background: url("https://api.iconify.design/fontisto/date.svg") no-repeat
    center center / contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: brightness(0) saturate(100%) invert(74%) sepia(23%)
    saturate(423%) hue-rotate(171deg) brightness(85%) contrast(84%);
  filter: brightness(0) saturate(100%) invert(74%) sepia(23%) saturate(423%)
    hue-rotate(171deg) brightness(85%) contrast(84%);
}
.input[type="date"]:focus::-webkit-calendar-picker-indicator {
  -webkit-filter: brightness(0) saturate(100%) invert(48%) sepia(64%)
    saturate(5423%) hue-rotate(308deg) brightness(93%) contrast(92%);
  filter: brightness(0) saturate(100%) invert(48%) sepia(64%) saturate(5423%)
    hue-rotate(308deg) brightness(93%) contrast(92%);
  z-index: 100;
}
.invitation-question-single-option {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.invitation-question-submit-button-container {
  margin: 2rem auto 0 auto;
}
@media (min-width: 1080px) {
  .preview-animation-envelope {
    height: 870px;
    width: auto;
  }
  .invitation-question > div {
    margin: 0 0 0 1.5rem;
  }
  .invitation-questionary-container {
    padding: 0 1rem;
  }
  .invitation-button-confirm-container {
    right: -30%;
    top: 11%;
    bottom: unset;
    /* margin: 0 auto; */
  }
}
.invitation-page-map {
  border: 2px var(--purple) solid;
  border-radius: 18.239px;
}

@media (min-width: 1025px) {
  .invitation-questionary-container {
    width: 70vw;
  }
  .invitation-button-confirm-container {
    right: -65%;
    top: 49%;
  }
  /* .invitation-page-content {
    display: unset;
  } */
}
@media (min-width: 1439px) {
  .invitation-questionary-container {
    width: 60vw;
  }
}

/* answer confirmation */
.send-answer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.send-answer-img {
  max-width: 31rem;
  width: 100%;
}
