.radio-container {
  position: relative;
  width: 1rem;
  height: 1rem;
}

.radio-input {
  opacity: 0;
}
.radio-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid #6e90b6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-label::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: auto;
}
.radio-input:checked + .radio-label:after {
  background-color: #e33d8c;
}
.radio-input:checked + .radio-label {
  border: 2px solid #e33d8c;
}
