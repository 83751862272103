.changepass__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.changepass__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.changepass__slot {
  display: flex;
  flex-direction: column;
}

.changepass__label {
  font-size: 16px;
  font-weight: 700;
  color: var(--purple);
}

.changepass__button {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}

.changepass__input,
.changepass__input-error {
  width: 100%;
  border-radius: 16px;
  border: 1px solid grey;
  font-size: 16px;
  padding: 0px 10px;
  outline: none;
}

.changepass__input:focus,
.changepass__input-error:focus {
  outline: 1px solid var(--purple);
  border: none;
}

.changepass__input-error {
  border: 1px solid red;
}

.changepass__error {
  font-size: 14px;
  color: red;
  font-weight: 600;
}

.changepass__error:first-of-type {
  margin-top: 5px;
}

.changepass__icon {
  font-size: 25px;
  color: var(--purple);
}
