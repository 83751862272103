.verifyAccount__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--defaultBackground);
  /* height: 50vh; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 2rem;
}
.verifyAccount__main h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: var(--text);
  text-align: center;
}
.img-verify-account {
  max-width: 100%;
  width: calc(300px + (400 - 300) * ((100vw - 450px) / (1400 - 450)));
}
.verifyAccount__text {
  font-size: 1.3em;
  margin: 0 0 30px 0;
  overflow-wrap: break-word;
  width: 85%;
  text-align: center;
}
.verifyAccount__text span {
  font-weight: 700;
  text-transform: uppercase;
}
