.custom-color-palette {
  margin-bottom: 20px;
  overflow: auto;
  overflow-x: hidden;
  padding: 12px;
}

.color-palette-title {
  font-weight: 400;
  font-size: 14px;
  color: #0d060f;
}
.container-line {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 1rem;
}
.container-line::after {
  position: absolute;
  content: "";
  bottom: -0.5rem;
  width: 100%;
  height: 2px;
  background-color: var(--purpleLight);
}
.texture-none {
  position: relative;
  padding: 16px;
  border: 1px solid var(--secondary-color);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 2px 2px 5px #0000005e;
}

.texture {
  position: relative;
}
.texture::after,
.texture-none::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: white;
  top: 6px;
  right: 6px;
  border: 1px solid var(--fuchsia);
}
.texture-selected::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--fuchsia);
  top: 9px;
  right: 9px;
  z-index: 1;
}
