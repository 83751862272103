:root {
  --backgroundImg: url("https://www.transparenttextures.com/patterns/light-paper-fibers.png");
}
.no-animated-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 280px;
}
.no-animate {
  position: absolute;
}

.wrap-envelope-transform {
  position: absolute;
  width: 100%;
  height: 100%;
}
.envelope--back {
  position: absolute;
  transform: rotateY(90deg);
  z-index: 0;
  background-color: #d0d0d0a2;
  background-image: var(--backgroundImg);
  box-shadow: 0 0 80px grey;
}

.envelope--back-laterals {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.envelope--back-laterals * {
  background-image: var(--backgroundImg);
}

.envelope--back-lateral {
  position: absolute;
  box-shadow: 0 0 10px grey;
}

.envelope--back-lateral:nth-of-type(1) {
  width: 70%;
  height: 80%;
  top: 17%;
  left: -43%;
  border-radius: 81px;
  transform: rotate(-42deg);
  z-index: 4;
}

.envelope--back-lateral:nth-of-type(2) {
  width: 70%;
  height: 80%;
  top: 17%;
  right: -43%;
  border-radius: 81px;
  transform: rotate(42deg);
  z-index: 4;
}

.envelope--square .envelope--back-lateral:nth-of-type(1) {
  width: 64%;
  height: 86%;
  top: 7.4%;
  left: -33%;
  border-radius: 70px;
  transform: rotate(-34deg);
}

.envelope--square .envelope--back-lateral:nth-of-type(2) {
  width: 64%;
  height: 86%;
  top: 7.4%;
  right: -33%;
  border-radius: 70px;
  transform: rotate(34deg);
}

.envelope--back-bottom {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.envelope--back-bottom * {
  background-image: var(--backgroundImg);
}

.envelope--back-bottom-element {
  position: absolute;
  margin: 0px auto;
  height: 52%;
  width: 41.5%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0px -38px 27px -41px grey;
}
.envelope--back-bottom-element::after {
  position: absolute;
  content: "";
  top: 5%;
  left: -50%;
  width: 312px;
  height: 455px;
  background-color: inherit;
  background-image: var(--backgroundImg);
  z-index: -1;
  border-radius: 86px;
  transform: rotate(35deg);
  box-shadow: 0 0 10px grey;
}
.envelope--back-bottom-element::before {
  position: absolute;
  content: "";
  top: 5%;
  right: -50%;
  width: 312px;
  height: 455px;
  background-color: inherit;
  background-image: var(--backgroundImg);
  z-index: -1;
  border-radius: 86px;
  transform: rotate(-35deg);
  box-shadow: 0 0 10px grey;
}
.envelope--square .envelope--back-bottom-element {
  width: 35%;
}
.envelope--square .envelope--back-bottom-element::after {
  top: 1%;
  left: -61%;
  width: 298px;
  height: 579px;
  transform: rotate(30deg);
}
.envelope--square .envelope--back-bottom-element::before {
  top: 1%;
  right: -61%;
  width: 298px;
  height: 579px;
  transform: rotate(-30deg);
}

.envelope--back-tongue-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 4;
  transform-origin: top;
  transform: rotateX(180deg);

  top: 0;
}

.envelope--back-tongue {
  position: relative;
  border-radius: 0 0 150px 150px;
  margin: auto;
  width: 39%;
  height: 65%;
  box-shadow: 0px -25px 69px 25px grey;
  background-image: var(--backgroundImg);
}
.envelope--back-tongue::after {
  position: absolute;
  content: "";
  top: -38%;
  left: 75%;
  margin: auto;
  background-color: inherit;
  transform: rotate(43deg);
  width: 73%;
  border-radius: 0 72px 0 0;
  height: 120%;
  box-shadow: 104px 30px 86px -98px grey;
  background-image: var(--backgroundImg);
}
.envelope--back-tongue::before {
  position: absolute;
  content: "";
  top: -38%;
  right: 75%;
  margin: auto;
  background-color: inherit;
  transform: rotate(-43deg);
  width: 73%;
  border-radius: 72px 0 0 0;
  height: 120%;
  box-shadow: -104px 30px 86px -98px grey;
  background-image: var(--backgroundImg);
}

.envelope--square .envelope--back-tongue {
  width: 39%;
  height: 65%;
}
.envelope--square .envelope--back-tongue::after {
  top: -22%;
  left: 72%;
  transform: rotate(32deg);
  width: 73%;
  height: 106%;
}
.envelope--square .envelope--back-tongue::before {
  top: -22%;
  right: 72%;
  transform: rotate(-32deg);
  width: 73%;
  height: 106%;
}

.envelope--back .card-back {
  transform: rotateY(0deg);
}
