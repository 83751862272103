.guest-list-board-container {
  padding: 1rem;
  position: relative;
}
.guest-send-invitation-button {
  position: absolute;
  top: -4rem;
  right: 0;
}
.guest-list-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 1rem 0;
}
.guest-list-search {
  width: 344px;
  height: 48px;
  position: relative;
}

.guest-list-search svg {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  color: var(--fuchsia);
}
.table-guests-delete-all {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}
.buttons-add-guest-container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-add-guest {
  background-color: var(--purple);
}
.button-export-list {
  color: #6b3378;
  outline-color: #6b3378;
}
.button-export-list:hover {
  color: #6b3378;
}
.select-options-actions {
  font-weight: 700;
}

/* ------------------------------------------------------------- */
/* add one guest */
/* ------------------------------------------------------------- */
.add-groups-container {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 2rem;
  max-height: 90vh;
}
@media (min-width: 480px) {
  .add-groups-container {
    width: 90vw;
  }
  .guest-list-board-container {
    padding: 2rem;
  }
}
@media (min-width: 1080px) {
  .add-groups-container {
    width: 80vw;
  }
}
@media (min-width: 1441px) {
  .add-groups-container {
    width: 1200px;
  }
}

.add-groups-container h3 {
  font-size: 32px;
  margin: 0;
}
.add-groups-container form {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 0;
}
.type-add-guest {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}
.single-or-group-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  margin: 0 0 2rem 0;
}

.single-or-group {
  display: flex;
  gap: 1rem;
}
.add-new-contact-type {
  cursor: pointer;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: var(--text);
}
.one-guest-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 200px));
  gap: 2rem 1rem;
  justify-content: space-between;
  margin: 0 0 3rem 0;
}
.one-guest-container .info-input-container {
  width: 100%;
}
.oneGuest-confirmated {
  width: 300px;
}
.oneGuest-confirmated > label {
  display: block;

  margin: 0 0 0.8rem 12px;
}
.oneGuest-confirmated-inputs {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}
.oneGuest-confirmated-inputs > div {
  display: flex;
  gap: 0.6rem;
}
.group-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
  gap: 2rem 4rem;
  margin: 0 0 3rem 0;
}
.group-container .info-input-container {
  width: 100%;
}
.gues-groups-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.gues-groups-container > div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem 0 0;
}
.gues-groups-container .info-input-container {
  width: 200px;
}
.gues-groups {
  display: flex;
  flex-direction: column;
  gap: 27px;
}
.gues-groups-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0 0 1rem 2rem;
}
.gues-group-check {
  display: flex;
  gap: 4rem;
  margin: 0 0 0 5rem;
}
.gues-group-check > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.is_confirmated {
  min-width: 40px;
  width: 40px;

  height: 25px;
}
.is_confirmated + label::before {
  background-size: 56%;
}
.delete {
  width: 2rem;
  height: 50px;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
}
.delete svg {
  height: 1.2rem;
  width: inherit;
  color: var(--purple);
  margin: auto;
}

.add-guest-grpup-button p {
  font-weight: 700;
}
.submit-list-group-button {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  gap: 2rem;
  margin-top: 2rem;
}
@media (min-width: 780px) {
  .gues-groups-container > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem 0 0;
  }
}
@media (min-width: 1180px) {
  .oneGuest-confirmated-inputs {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

/* --------------------------------------------------------------------------------------------------------------------------- */
/* Table */
/* --------------------------------------------------------------------------------------------------------------------------- */

.table-guest-container {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.table-guest-container > p {
  margin-bottom: 0px;
}
.table-guest-static-filters-container {
  display: flex;
  justify-content: space-between;
}
.input-actions {
  max-width: 200px;
  height: 42px;
  margin: -1rem 0 0 0;
}
.table-guest-static-filters {
  display: flex;
  gap: 0.25rem;
}
.table-guest-static-filter {
  background-color: #ece9f3;
  margin: 0;
  padding: 0.3rem;
  font-size: 12px;
  text-align: center;
  border: 1px solid var(--purple);
  border-bottom: none;
  border-radius: 16px 16px 0px 0px;
  color: var(--textPurple);
  cursor: pointer;
}
.filter-button-active {
  background-color: var(--defaultBackground);
  font-weight: 700;
  color: var(--purple);
}
.table-guests {
  border: 1px solid var(--purple);
  overflow-x: auto;
}
.table-guest-title {
  padding: 0 1.5rem 0 0;
  background-color: var(--purple);
  min-width: 60rem;
}
.table-guest-title p {
  font-weight: 700;
  font-size: 16px;
  color: var(--defaultBackground);
  height: 3rem;
  align-items: center;
  display: flex;
  margin-bottom: 0;
}

.table-guest-email {
  position: relative;
  padding: 0 0 0 2rem;
  width: 14rem;
  margin: 0 0 0 1rem;
}
.table-guest-email p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.table-guest-name {
  position: relative;
  width: 10rem;
}
.table-guest-name p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.table-guest-phone {
  display: none;
  width: 7rem;
}
.table-guest-modified {
  width: 10rem;
}
.table-guest-modified p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.table-guest-numbers-menbers {
  width: 8rem;
}
.table-guest-numbers-menbers p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: auto;
}
.table-guest-numbers-menbers svg {
  color: var(--purple);
  margin: 0 0 0 1rem;
  height: 0.9rem;
  width: 1rem;
}
.table-guest-state {
  width: 4rem;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.table-guest-state p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: auto;
}
@media (min-width: 480px) {
  .table-guest-static-filter {
    font-size: 14px;
  }
}

/* .open-state,
.no-open-state {
  display: block;
  height: 1rem;
  width: 1rem;
  margin: auto;
  border-radius: 20px;
} */
.has-answer {
  /* background-color: #f4c542; */
  background-color: #08c96c;
}
.is-sent {
  background-color: #08c96c;
}
.is-not-sent {
  background-color: #9ca7b3;
}
.error-to-sent {
  background-color: #f50a0a;
}

.table-guest-assist {
  width: 4.5rem;
  margin: 0;
  display: flex;
  justify-content: center;
}
.table-guest-assist p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  margin: 0;
  width: min-content;
}
.table-guest-younger {
  width: 5rem;
  margin: 0;
  display: flex;
  justify-content: center;
}
.table-guest-younger p {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  margin: 0;
  width: min-content;
}

.table-guest-actions {
  width: 6.5rem;
  display: flex;
  justify-content: space-evenly;
}
.table-guest-actions svg {
  color: var(--fuchsia);
  cursor: pointer;
}
.table-guests-container {
  display: grid;
  gap: 24px;
}
.table-guests-container .table-guest-check {
  border: 2px solid #9ca7b3;
}
.table-guest-email,
.table-guest-name,
.table-guest-numbers-menbers,
.table-guest-state,
.table-guest-check,
.table-guest-confirmation,
.table-guest-actions,
.table-guest-assist,
.table-guest-younger,
.table-guest-modified {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.order-active::after {
  position: absolute;
  content: url("https://api.iconify.design/fa6-solid/arrow-up-short-wide.svg?color=%23fcfcfc");
  background-color: var(--primaryColor);
  left: 110%;
}
.table-guest-title .table-guest-name P::before,
.table-guest-title .table-guest-email p::before,
.table-guest-title .table-guest-modified p::before {
  position: absolute;
  content: url("https://api.iconify.design/fa6-solid/arrow-down-short-wide.svg?color=%23fcfcfc");
  left: 110%;
  background-color: var(--primaryColor);
}
.table-guest-body-container {
  min-width: 60rem;
}
.table-guest-body {
  position: relative;
  min-height: 3rem;
  padding: 0.5rem 1.5rem 0.5rem 0;
  border: 1px solid rgba(107, 51, 120, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-guest-body > p {
  height: 2rem;
  align-items: center;
  display: flex;
  color: var(--textPurple);
}
.table-guest-body .table-guest-state,
.table-guest-body .table-guest-assist,
.table-guest-body .table-guest-younger,
.table-guest-body .table-guest-confirmation,
.table-guest-body .table-guest-numbers-menbers {
  text-align: center;
}
.table-guest-body .table-guest-email::after {
  position: absolute;
  content: url("https://api.iconify.design/ep/arrow-right-bold.svg");
  -webkit-filter: brightness(0) saturate(100%) invert(31%) sepia(70%)
    saturate(2003%) hue-rotate(308deg) brightness(95%) contrast(87%);
  filter: brightness(0) saturate(100%) invert(31%) sepia(70%) saturate(2003%)
    hue-rotate(308deg) brightness(95%) contrast(87%);
  left: -0rem;
  transition: all 0.5s;
  cursor: pointer;
}
.table-guest-body .table-guest-state p,
.table-resumen-state-container p:first-child {
  position: relative;
  height: 20px;
  width: 20px;
  margin: auto;
  color: var(--white);
  text-align: center;
  line-height: 1.5;
  border-radius: 4px;
}
.table-resumen-state-text {
  margin-top: 4px;
  margin-bottom: 4px;
}
.table-guest-menbers {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0;

  padding: 0 0 0 5rem;
}
.table-guest-menbers li {
  height: 0px;
  opacity: 0;

  overflow: hidden;
  transition: all 0.5s;
}
.table-guest-menbers li svg {
  color: var(--fuchsia);
  margin: 0 0 0 1rem;
}
.group-active + .table-guest-menbers li {
  height: 1rem;
  opacity: 1;
  margin: 1rem 0 0 0;
  overflow: unset;
  transition: all 0.5s;
}
/* .group-active + .table-guest-menbers .table-guest-no-confirmated {
} */
.group-active + .table-guest-menbers .table-guest-confirmated-true {
  list-style-image: url("https://api.iconify.design/charm/tick.svg?color=%2308c96c");
}
.group-active + .table-guest-menbers .table-guest-confirmated-false {
  list-style-image: url("https://api.iconify.design/charm/cross.svg?color=%23f50a0a");
}

.group-active .table-guest-email::after {
  transform: rotate(90deg);
  /* transition: all 0.5s; */
}
@media (min-width: 480px) {
  .table-guest-static-filter {
    padding: 0.5rem;
    text-align: start;
  }
}

/* ------------------------------------------------------------------------------------------------------- */
/* modalDelete */
/* ------------------------------------------------------------------------------------------------------- */

.table-group-delete-modal {
  width: 360px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.table-group-delete-modal .table-group-delete-img {
  position: absolute;
  top: 0;
  background-color: var(--purpleHover);
  width: 100%;
  height: 8rem;

  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-group-delete-modal-title {
  margin: 8rem auto 0 auto;
  color: var(--textPurple);
  font-weight: 700;
  font-size: 20px;
}
.table-group-delete-modal-content {
  width: 250px;
  text-align: center;
  line-height: 22px;
}

.table-group-delete-cancel-button {
  color: var(--fuchsia);
}
.table-group-delete-cancel-button p {
  font-weight: 700;
}
.table-group-delete-delete-button {
  width: 115px;
  min-width: 115px;
  min-height: 36px;
  height: 36px;
  padding: 0 0.2rem;
}

.group-selected {
  background-color: var(--purpleHover);
}

.table-resumen {
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.table-resumen-state-container {
  display: grid;
  grid-template-columns: 2rem 100%;
  gap: 1rem;
}
.table-resumen-states-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.table-group-delete-buttons {
  display: flex;
  gap: 2rem;
}

/* --------------------------------------------------------------------------------------- */
/* submit Modal */
/* --------------------------------------------------------------------------------------- */

.submit-envelope-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}
.submit-envelope-form-container {
  /* margin: 0 1rem; */
  max-width: 50rem;
  overflow: hidden;
}
.img-submit-envelope-container {
  position: relative;
  left: -6.5rem;
  width: calc(100% + 13rem);
  height: 25rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--greyHover);
}
.img-submit-envelope-container > div {
  scale: 0.3;
}

.submit-envelope-ask {
  margin: 2.5rem 0;
}
.img-submit-envelope-container img {
  width: 29rem;
  height: 21.5rem;
}
.submit-checkBox {
  display: flex;
  gap: 1rem;
  margin: 2.5rem 0;
  width: 90%;
  height: 100%;
}
.submit-checkBox .error-text::after {
  top: -11%;
}
.submit-buttons-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.alert-select-groups-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.alert-select-groups-container p {
  font-weight: 700;
  color: var(--text);
}

/* ------ SUBMITTED ------ */

.submitted-img {
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  width: calc(250px + (350 - 250) * ((100vw - 450px) / (1400 - 450)));
}
.submitted-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: var(--purple);
  text-align: center;
}
.submitted-content {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  margin: 0 auto 30px auto;
  overflow-wrap: break-word;
  width: 85%;
  text-align: center;
  color: var(--purple);
}
.table-resumen-pagination-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
}
.table-resumen-pagination-icon {
  cursor: pointer;
  color: var(--fuchsia);
  width: 0.8rem;
}
