/* ------------------------------------------------------------- */
/* Analitics */
/* ------------------------------------------------------------- */
#chart-data-container-title {
  font-weight: bold;
}
.total-guests {
  color: #6b3378;

  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
}
.traquing-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.tracking-analitics {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tracking-analitics h1 {
  color: #6b3378;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.1px;
}
.chart-data {
  width: 40%;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  margin: 0 auto;
}
.tracking-questions {
  min-width: 55%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 2rem;
  margin: 0;
}
.tracking-questions h4 {
  color: #6b3378;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.1px;
  line-height: 27px;
  margin: 1rem 0 1rem 0; 
}

.tracking-questions select {
  min-height: 48px;
  margin: 0 0 1.5rem 0;
}

.tracking-questions-advice{
  margin-top: .7rem;
  font-weight: bold;
}

.tracking-next-steps {
  display: flex;
  gap: 2rem;
}
.tracking-next-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.tracking-print,
.tracking-export {
  border: 1px solid;
  border-radius: 16px;
  width: 4.5rem;
  height: 4.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tracking-print {
  background: #fce7f1;
  color: var(--fuchsia);
}
.tracking-export {
  background: #e0fefd;
  color: #29f6f2;
}
.tracking-next-step svg {
  height: 3rem;
  margin: auto;
  width: 3rem;
}
.chart-data-container {
  box-shadow: 0px 1px 3px 1px rgba(51, 51, 51, 0.15);
  -webkit-filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.3));
  filter: drop-shadow(0px 1px 2px rgba(51, 51, 51, 0.3));
  background-color: #ffffff;
  border-radius: 4px;
}
.chart-data-container > div {
  padding: 1.5rem 0.5rem;
}
.chart-data-graphic {
  padding: 1rem;
  background-color: #ffffff;
  display: flex;
  border-top: 2px solid #ececec;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
}
.question-first-type {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-bottom: 10px;
}
.possibility-render-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 0.2rem;
}
.possibility-render:nth-child(2n + 1) .answer-bar .bar-wrapper {
  background: #fef6fa;
  box-shadow: 0px 2px 10px rgba(107, 84, 158, 0.1),
    inset 0px 4px 4px rgba(107, 84, 158, 0.15);
  border-radius: 16px;
}
.possibility-render:nth-child(2n + 2) .answer-bar .bar-wrapper {
  background: #fef6fa;
  box-shadow: 0px 2px 10px rgba(227, 61, 140, 0.1),
    inset 0px 4px 4px rgba(227, 61, 140, 0.15);
  border-radius: 16px;
}
.answer-bar .bar-container {
  border-radius: 16px;
  padding: 0;
  margin: 0;
}
.answer-bar {
  width: 89%;
}
.possibility-no-results {
  margin: 4px 0;
}
.question-second-type .possibility-no-results .answer-count {
  justify-content: flex-start;
  gap: 1rem;
}
.question-second-type .answer-percentage {
  text-align: right;
  padding-bottom: 5px;
}
.question-first-type .answer-count {
  border-radius: 50px;
  gap: 10px;
}
.possibility-render:nth-child(2n + 1) .answer-bar .bar-wrapper {
  background: #fef6fa;
  box-shadow: 0px 2px 10px rgba(107, 84, 158, 0.1),
    inset 0px 4px 4px rgba(107, 84, 158, 0.15);
  border-radius: 16px;
}
.possibility-render:nth-child(2n + 2) .answer-bar .bar-wrapper {
  background: #fef6fa;
  box-shadow: 0px 2px 10px rgba(227, 61, 140, 0.1),
    inset 0px 4px 4px rgba(227, 61, 140, 0.15);
  border-radius: 16px;
}
.answer-bar .bar-container {
  border-radius: 16px;
  padding: 0;
  margin: 0;
}
.answer-bar {
  width: 80%;
  margin: 0 0 0 0.5rem;
}
.possibility-no-results {
  margin: 4px 0;
}
.question-second-type .possibility-no-results .answer-count {
  justify-content: flex-start;
  gap: 1rem;
}
.question-second-type .answer-percentage {
  text-align: right;
  padding-bottom: 5px;
}
.question-first-type .answer-count {
  border-radius: 50px;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question-first-type .possibility-render {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 80px;
  min-height: 100px;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
}
.question-second-type .answer-count {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.question-second-type .answer-count > P {
  width: 19%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.question-second-type {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.question-first-type .possibility-render:nth-child(5n + 1) {
  color: var(--purple);
  font-weight: bold;
  background-color: #efe1f2;
}
.question-first-type .possibility-render .answer-count {
  border: 5px solid;
}
.question-first-type .possibility-render:nth-child(5n + 1) .answer-count {
  border-color: var(--purple);
}

.question-first-type .possibility-render:nth-child(5n + 2) {
  color: #456488;
  background-color: #e2e9f0;
  font-weight: bold;
}
.question-first-type .possibility-render:nth-child(5n + 2) .answer-count {
  border-color: #456488;
}
.question-first-type .possibility-render:nth-child(5n + 3) {
  color: #00baf5;
  background-color: #def7ff;
  font-weight: bold;
}
.question-first-type .possibility-render:nth-child(5n + 3) .answer-count {
  border-color: #00baf5;
}
.question-first-type .possibility-render:nth-child(5n + 4) {
  color: #09d6d2;
  background-color: #e0fefd;
  font-weight: bold;
}
.question-first-type .possibility-render:nth-child(5n + 4) .answer-count {
  border-color: #09d6d2;
}
.question-first-type .possibility-render:nth-child(5n + 5) {
  color: #069996;
  background-color: #057b781a;
  font-weight: bold;
}
.question-first-type .possibility-render:nth-child(5n + 5) .answer-count {
  border: 3px solid #069996;
}
@media (min-width: 480px) {
  .chart-data-container > div {
    padding: 1.5rem 3rem;
  }
  .traquing-container {
    padding: 2rem;
  }
  .tracking-questions {
    margin: 0 auto;
  }
}
/* ------------------------------------------------------------- */
/* Table */
/* ------------------------------------------------------------- */

.tracking-list {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.table-grid {
  max-width: 1000px;
  margin: auto;
  display: grid;
  grid-template-columns: 3fr repeat(8, 1fr);
  grid-gap: 2em;
  color: #222;
  border-bottom: 1px solid #ddd;
  padding: 1em 0;
}

/* .tracking-table-guests {
  display: grid;
  grid-template-columns: 20% 5% 10% 20% 10% 5% 10% 10% 10%;
} */
.tracking-table-header {
  background-color: #6b3378;
  color: #fcfcfc;
}

