:root {
  --heigth-step: 50px;
  --primary-color: #dfc4e6;
  /* --primary-color: #d2addb; */

  --secondary-color: #6b3378;
}

@media (min-width: 1080px) {
  :root {
    --heigth-step: 50px;
  }
}
@media (min-width: 1400px) {
  :root {
    --heigth-step: 72px;
  }
}
.stepper-container {
  margin: 0 auto;
  text-align: center;
}
.stepper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 8px;
}
.step {
  background-color: var(--primary-color);
  position: relative;
  list-style-type: none;
  height: var(--heigth-step);
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
}
.stepper [disable="true"] {
  cursor: not-allowed; /* -webkit-filter: grayscale(1); */
  -webkit-filter: grayscale(0.3);
  filter: grayscale(0.3);
  /* background-color: #ffffff; */
  -webkit-filter: brightness(0.8);
  filter: brightness(0.8);
}
.stepper [disable="true"]::after {
  opacity: 0;
}
button:disabled {
  opacity: 0.5;
  cursor: context-menu !important;
}
.stepper [disable="true"] .step-text {
  color: #111111;
}

.step-text,
.step-number {
  font-size: 15px;
  color: #43204b;
  position: absolute;
  left: 0%;
  right: 0%;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 20;
}
@media (min-width: 1080px) {
  .step-text,
  .step-number {
    font-size: 15px;

    left: 0%;
    right: 0%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}
@media (min-width: 1400px) {
  .step-text,
  .step-number {
    font-size: 20px;
    left: 0%;
    right: 0%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

.step-active {
  /* background-color: var(--secondary-color); */
  background-color: var(--purple);
  width: 120%;
  min-width: 6.5rem;
}
.step-active .step-text {
  font-weight: 700;
  color: #fcfcfc;
}

.step-active:last-of-type::after,
.step-active:first-of-type::before {
  display: none;
}

.step-to-step {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  color: var(--floatLaboratory) !important;
}
.step-to-step * {
  color: var(--floatLaboratory) !important;
}
.button-next:hover,
.button-next:hover *,
.button-preview:hover,
.button-preview:hover * {
  color: var(--secondaryColor) !important;
}

/* .button-next,
.button-preview {
  color: var(--floatLaboratory) !important;
  font-weight: 400;
}
.button-next svg,
.button-preview svg {
  color: var(--floatLaboratory) !important;
  font-weight: 400;
} */
.step-text {
  display: none;
}
.step-active .step-text {
  position: relative;
  display: flex;

  /* margin: auto 1rem; */
  text-align: center;
}
.step-active .step-number {
  display: none;
}

@media (min-width: 750px) {
  .stepper {
    overflow: hidden;
  }
  .step-active .step-text {
    right: -12%;
    left: 8%;
  }

  .step {
    border-radius: 0;
  }
  .step::after {
    content: "";
    position: absolute;
    right: -7%;
    top: 4%;
    height: calc(var(--heigth-step) - 3px);
    width: calc(var(--heigth-step) - 3px);
    border-radius: 13px 9px;
    background-color: var(--primary-color);
    transform: scaleY(1.4) rotate(45deg);
    z-index: 19;
  }
  .step-number {
    display: none;
  }
  .step-text {
    display: flex;
  }
  .step-active {
    width: 100%;
    min-width: none;
  }
  .step-active::after {
    /* background-color: var(--secondary-color); */
    background-color: var(--purple);

    z-index: 1;
  }
  .step-active:first-of-type .step-text {
    position: absolute;
    left: 0;
  }
  .step-active:last-of-type .step-text {
    position: absolute;
    left: 0;
  }
}
@media (min-width: 880px) {
  .step::after {
    right: -12%;
    transform: rotate(45deg);
  }
}
