/* .design-steps {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
} */

.design-steps-first-section {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.design-steps-second-section {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  top: -2.7rem;
}

.undo-redo-wrapper {
  display: flex;
}

.design-steps-wrapper {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.design-steps-wrapper > .nav-item {
  position: relative;
  font-size: 16px;
  cursor: pointer;
  margin-right: 15px;
  margin-left: 15px;
  padding: 0;
  width: initial;
  color: var(--floatLaboratory);
}
.design-steps-wrapper > .nav-item:hover {
  color: var(--secondaryColor);
}
.design-steps-wrapper > .nav-item::after {
  bottom: -0.5rem;
}

.save-design-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-left: 0;
}
/* .save-design-button-wrapper * {
  color: var(--floatLaboratory);
}
.save-design-button-wrapper:hover * {
  color: var(--secondaryColor);
} */
.save-design-button {
  font-weight: 400;
  right: 1.5rem;
  color: var(--floatLaboratory);
}
.saving {
  position: relative;
}
.saving::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 100%;
  height: 4px;
  background-color: var(--secondaryColor);
}
@media (min-width: 480px) {
  .save-design-button-wrapper {
    margin-right: 1.5rem;
    margin-left: 20px;
  }
}
