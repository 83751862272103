#polotno-app-container {
  height: 98vh;
}

.polotno-color-picker-wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 2%;
  padding-left: 2%;
  margin-top: 2%;
  margin-bottom: 2rem;
  margin-left: 5px;
  border-radius: 2px;
}

.polotno-color-picker-title {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #0d060f;
}

.polotno-workspace-inner::-webkit-scrollbar {
  display: none;
}

/*START side-panel */

.polotno-side-panel-tab .iconify {
  font-size: 23px;
  color: "#cfa6d9";
}

.polotno-side-tabs-container::-webkit-scrollbar {
  display: none;
}

/*START overwrite colors for side-panel tabs */
.go1621360228 {
  border-radius: 12px 0 0 0;
  /* background-color: #6b3378 !important; */
  background-color: var(--purple);
  height: 100%;
  padding: 1rem;
}
.go846226717.bp5-navbar {
  border-radius: 0 12px 0 0;
}
.polotno-side-tabs-container .polotno-side-panel-tab::-moz-selection {
  background: #dfc4e6;
  color: #6b3378;
  position: unset;
  height: 100%;
}

.polotno-side-tabs-container .polotno-side-panel-tab:hover,
.polotno-side-tabs-container .polotno-side-panel-tab::selection,
.polotno-side-tabs-container .polotno-side-panel-tab.active {
  background: #dfc4e6;
  color: #6b3378;
  position: unset;
  /* height: 100%; */
}

.polotno-side-tabs-container .polotno-side-panel-tab {
  background-color: var(--purple);
  color: #cfa6d9;
}

/*START overwrite colors for custom icons (not from iconify) */
.icon-img:hover img {
  -webkit-filter: brightness(0) saturate(100%) invert(25%) sepia(23%)
    saturate(1755%) hue-rotate(244deg) brightness(94%) contrast(92%);
  filter: brightness(0) saturate(100%) invert(25%) sepia(23%) saturate(1755%)
    hue-rotate(244deg) brightness(94%) contrast(92%);
}

.icon-img .polotno-side-panel-tab::-moz-selection img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(23%) saturate(1755%)
    hue-rotate(244deg) brightness(94%) contrast(92%);
}

.icon-img .polotno-side-panel-tab::selection img {
  -webkit-filter: brightness(0) saturate(100%) invert(25%) sepia(23%)
    saturate(1755%) hue-rotate(244deg) brightness(94%) contrast(92%);
  filter: brightness(0) saturate(100%) invert(25%) sepia(23%) saturate(1755%)
    hue-rotate(244deg) brightness(94%) contrast(92%);
}

.icon-img .polotno-side-panel-tab.active img {
  -webkit-filter: brightness(0) saturate(100%) invert(25%) sepia(23%)
    saturate(1755%) hue-rotate(244deg) brightness(94%) contrast(92%);
  filter: brightness(0) saturate(100%) invert(25%) sepia(23%) saturate(1755%)
    hue-rotate(244deg) brightness(94%) contrast(92%);
}
.polotno-side-panel-tab path,
.polotno-side-panel-tab svg {
  height: 1.5rem;
  width: 1.5rem;
}

/*END overwrite colors for custom icons (not from iconify) */

/*END overwrite colors for side-panel tabs */

/*START overwrite colors for side-panel tabs content */

.go242266285 {
  background-color: #dfc4e6;
  color: #6b3378;
}

.bp5-tab[aria-selected="true"],
.bp5-tab:not([aria-disabled="true"]):hover,
.bp5-tab:not([aria-disabled="true"]) {
  color: #6b3378;
  font-weight: 700;
}

.bp5-tab[aria-selected="true"] {
  box-shadow: inset 0 -3px 0 #6b3378 !important;
}

.bp5-button.bp5-minimal.polotno-close-panel {
  color: #fef6fa;
  font-weight: 400;
  background: #b06bbf;
  border: 1px solid #dfc4e6;
  border-radius: 8px;
}

.bp5-tab-list.bp5-large {
  margin-bottom: 5%;
}

.page-size-button.bp5-button:not([class*="bp5-intent-"]) {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: #1b0d1e;
  background-color: #dfc4e6;
  box-shadow: none;
  margin-bottom: 3%;
}

.page-size-button.bp5-button:not([class*="bp5-intent-"])::-moz-selection {
  color: #1b0d1e;
  background-color: #e1d6e4;
  border-radius: 2px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
}

.page-size-button.bp5-button:not([class*="bp5-intent-"])::selection,
.page-size-button.bp5-button:not([class*="bp5-intent-"]):hover {
  color: #1b0d1e;
  background-color: #e1d6e4;
  border-radius: 2px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
}

.bp5-card {
  background-color: #b06bbf !important;
  color: #fcfcfc !important;
}

.bp5-card ~ .bp5-icon {
  color: #fcfcfc !important;
}

/*END overwrite colors for side-panel tabs content*/

/*START position sidebar elements */

.polotno-panel-container {
  display: flex;
  justify-content: center;
  min-height: 70vh;
  overflow: hidden;
  min-width: 100%;
}
@media (min-width: 480px) {
  .polotno-panel-container {
    display: flex;
    justify-content: center;
    min-height: initial;
    overflow: hidden;
    min-width: initial;
  }
}
.page-size-button {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 20px !important;
  padding: 0.5rem 0;
}
.page-size-button img {
  -webkit-filter: invert(79%) sepia(94%) saturate(1097%) hue-rotate(282deg)
    brightness(98%) contrast(93%);
  filter: invert(79%) sepia(94%) saturate(1097%) hue-rotate(282deg)
    brightness(98%) contrast(93%);
}

.custom-panel {
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: hidden;
}

.bp5-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bp5-tabs .bp5-tab-indicator-wrapper {
  display: none;
}

/*END position sidebar elements */

/*START hide unwanted text templates in polotno text section */

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(1) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(2) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(1) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(3) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(2) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(3) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(4) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(5) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(6) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(5) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(7) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(10) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(11) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(9) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(10) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(29) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(27) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(26) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(21) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(13) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(14) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(14) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(20) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(15) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(18) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(2)
  > div:nth-child(19) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(11) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(12) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(22) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(24) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(30) {
  display: none;
}

#polotno-app-container
  > div.go1236784588
  > div
  > div.go242266285.bp5-navbar.polotno-panel-container
  > div
  > div.go3011404647
  > div
  > div:nth-child(1)
  > div:nth-child(29) {
  display: none;
}

/*END hide unwanted text templates in polotno text section */

/*END side-panel*/

/* START toolbar */
.bp5-button.bp5-minimal {
  font-style: normal;
  font-weight: 600;
  color: #6b3378;
}

.bp5-button.bp5-minimal::-moz-selection {
  color: #6b3378;
  background-color: #e1d6e4;
}

.bp5-button.bp5-minimal:hover,
.bp5-button.bp5-minimal::selection,
.bp5-button.bp5-minimal.active,
.bp5-button.bp5-minimal:active,
.bp5-button.bp5-minimal.bp5-active {
  color: #6b3378;
  background-color: #e1d6e4;
}

/* START change color of menus inside toolbar */
.bp5-menu-item {
  color: #6b3378;
}
.bp5-menu-item-icon .bp5-icon > svg:not([fill]) {
  fill: #6b3378;
}

.bp5-popover2,
.bp5-popover2 .bp5-heading {
  color: #6b3378;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

/* START overwrites progress bars */
.bp5-slider-track,
.bp5-slider-progress {
  height: 1px;
  background-color: #dfc4e6;
  border-radius: 2px;
}

.bp5-slider-progress.bp5-intent-primary {
  background-color: #dfc4e6;
}

.bp5-slider-handle {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  box-sizing: border-box;
  background: #fcfcfc;
  color: #fcfcfc;
  border: 1.6px solid #6b3378;
  box-shadow: 0 0 0 2px #dfc4e6;
}
/* END overwrites progress bar */

/* this might not actually do something */
.bp5-dark .bp5-button:not([class*="bp5-intent-"]):hover {
  /* background-color: #361a3c; */
  background-color: var(--purple);
}

/* icons */
.bp5-button .bp5-icon,
.bp5-button .bp5-icon-standard,
.bp5-button .bp5-icon-large {
  color: #6b3378;
}
@media (max-width: 480px) {
  #polotno-app-container div.go1236784588 {
    width: auto;
  }
  .polotno-side-tabs-inner {
    height: 100%;
  }

  .polotno-side-tabs-inner > .polotno-side-panel-tab {
    height: auto;
    padding: 9px;
    width: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .go1621360228 {
    border-radius: 0;
  }
  .go846226717.bp5-navbar {
    border-radius: 12px 12px 0 0;
  }
}
/* END toolbar */
