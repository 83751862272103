.closeaccount__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  height: 70vh;
  width: 100%;
}

.closeaccount__text {
  font-size: 32px;
  font-weight: 700;
  color: var(--purple);
}

.closeaccount__main-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-top: 20px;
}
