.recovery-password-container {
  max-width: 1056px;
  padding: 2rem 0 2rem 0;
  overflow-y: auto;
}
.recovery-password-tittle {
  font-weight: 700;
  font-size: 32px;
  color: #6b3378;
  margin: 0 0 1rem 0;
  text-align: center;
}
.recovery-password-content-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  padding: 0 0 2rem 0;
}
.recovery-password-img {
  display: none;
}
.recovery-password-content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  flex-basis: 90%;
}
.recovery-password-content .info-input-container {
  width: 100%;
}
.recovery-password-content > p {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  color: #6b3378;
}
.recovery-password-content form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.recovery-button-container {
  width: 14rem;
  align-self: flex-end;
}
.sign-acount {
  font-weight: 700;
  font-size: 16px;
  color: #6b3378;
  align-self: flex-end;
}
.sign-acount:hover {
  transform: scale(1.05);
  color: #6b3378;
  text-decoration: none;
}
@media (min-width: 1000px) {
  .recovery-password-img {
    display: flex;
    flex-basis: 20%;
  }
  .recovery-password-content > p {
    margin: 0 0 0 -3rem;
  }
  .recovery-password-content {
    flex-basis: 60%;
  }
  .recovery-password-content form {
    width: 85%;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------- */
/* check email */
/* ---------------------------------------------------------------------------------------------------------------------------------------------- */

.check-email-container {
  display: flex;
  flex-direction: column;
  gap: 6rem 0;
  align-items: center;
  text-align: center;
  padding: 0 3rem 0 3rem;
}
.check-email-content h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: #6b3378;
}
.check-email-content img {
  width: 100%;
  max-width: 660px;
}
.check-email-content p {
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  color: #28132d;
  text-align: initial;
}
.check-email-doubts p {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  letter-spacing: 0.1px;
  color: #28132d;
}
.check-email-doubts a {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  letter-spacing: 0.1px;
  color: #e33d8c;
}
