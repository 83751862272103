.invitation-save,
.invitation-no-save {
  position: fixed;
  bottom: 6rem;
  right: -5rem;
  /* left: 0; */
  min-width: 3rem;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: auto;
  background: inherit;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  border-radius: 3rem;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: save-invitation;
  animation-name: save-invitation;
}
.invitation-saved p,
.invitation-no-save p {
  margin: 0;
  font-weight: 400;
  font-size: 39px;
  line-height: 0;
  color: #fff;
}

.invitation-save,
.invitation-no-save {
  background: var(--defaultBackground);
}
.invitation-no-save p {
  font-size: 24px;
}

@-webkit-keyframes save-invitation {
  0% {
    right: -5rem;
  }
  50% {
    right: 3rem;
  }
  100% {
    right: -5rem;
  }
}

@keyframes save-invitation {
  0% {
    right: -5rem;
  }

  50% {
    right: 3rem;
  }
  100% {
    right: -5rem;
  }
}
