.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}
.info-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.container-autocomplete {
  height: inherit;
}
.info-event,
.info-personal {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  justify-items: center;
  gap: 3rem 4rem;
  margin: 2rem 0 0 0;
}
.info-field {
  display: flex;
  flex-direction: column;
}

.info-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 4px 12px;
}

.info-input-container {
  position: relative;
  height: 48px;
  width: 300px;
}

.input {
  border: 1px solid #8ba6c4;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  padding: 4px 4px 4px 8px;
}
.input:hover {
  box-shadow: 0px 1px 3px 1px rgba(227, 61, 140, 0.15),
    0px 1px 2px rgba(227, 61, 140, 0.3);
}
.input:focus {
  border: none;
  outline: 1px solid #e33d8c;
}
.input[type="date"]::-webkit-calendar-picker-indicator,
.input[type="time"]::-webkit-calendar-picker-indicator {
  position: relative;
}
.input-ok .input[type="date"]::-webkit-calendar-picker-indicator,
.input-ok .input[type="time"]::-webkit-calendar-picker-indicator {
  -webkit-filter: brightness(0) saturate(100%) invert(46%) sepia(64%)
    saturate(5561%) hue-rotate(309deg) brightness(94%) contrast(89%);
  filter: brightness(0) saturate(100%) invert(46%) sepia(64%) saturate(5561%)
    hue-rotate(309deg) brightness(94%) contrast(89%);
}
.input[type="date"]::-webkit-calendar-picker-indicator {
  content: "";
  height: 60%;
  width: 2rem;
  position: absolute;
  right: 2%;

  background: url("https://api.iconify.design/fontisto/date.svg") no-repeat
    center center / contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: brightness(0) saturate(100%) invert(74%) sepia(23%)
    saturate(423%) hue-rotate(171deg) brightness(85%) contrast(84%);
  filter: brightness(0) saturate(100%) invert(74%) sepia(23%) saturate(423%)
    hue-rotate(171deg) brightness(85%) contrast(84%);
}
.input[type="date"]:focus::-webkit-calendar-picker-indicator,
.input[type="time"]:focus::-webkit-calendar-picker-indicator {
  -webkit-filter: brightness(0) saturate(100%) invert(48%) sepia(64%)
    saturate(5423%) hue-rotate(308deg) brightness(93%) contrast(92%);
  filter: brightness(0) saturate(100%) invert(48%) sepia(64%) saturate(5423%)
    hue-rotate(308deg) brightness(93%) contrast(92%);
  z-index: 100;
}

.input[type="time"]::-webkit-calendar-picker-indicator {
  content: "";
  height: 60%;
  width: 2rem;
  position: absolute;
  right: 2%;
  background: url("https://api.iconify.design/ion/time-outline.svg") no-repeat
    center center / contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: brightness(0) saturate(100%) invert(74%) sepia(23%)
    saturate(423%) hue-rotate(171deg) brightness(85%) contrast(84%);
  filter: brightness(0) saturate(100%) invert(74%) sepia(23%) saturate(423%)
    hue-rotate(171deg) brightness(85%) contrast(84%);
}

.info-textarea .info-input-container {
  resize: none;
  height: 140px;
}
.info-textarea textarea {
  resize: none;

  height: 140px;
}

.error-text {
  position: absolute;
  bottom: -20px;
  width: 100%;
  left: 0;
  right: 0;
  top: 115%;
  margin: 0 0 0 12px;
  color: red;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.error {
  border: 1px solid #f50a0a;
  color: #f50a0a;
}
.error:focus {
  border: none;
  outline: 1px solid #f50a0a;
}

.input-ok input {
  border: 1px solid #e33d8c;
}
.input-ok::after,
.error-point {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: auto 0;
}
.error-point {
  background-color: red;
}
.qr-container {
  text-align: center;
  margin: 3rem auto 0 auto;
  max-width: 350px;
}

.input-qr {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 0 0;
  align-items: center;
}

.info-qr-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  /* height: 3rem; */
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background-color: #cccccc;
}
.info-qr-container svg {
  color: var(--purple);
  min-height: 2rem;
  min-width: 2rem;
}
.info-qr-container p {
  margin: 0;
  text-align: justify;
}
.qr-activate {
  background-color: var(--fuchsiaHover);
}

.map-input-container {
  position: relative;
  height: 48px;
  width: 300px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-container--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 2px solid #9ca7b3;
}
.map-container--icon svg {
  display: none;
}
.map-input:checked + .map-container--icon svg {
  font-size: 1.2rem;
  display: block;
  color: var(--white);
}
.map-input:checked + .map-container--icon {
  background-color: var(--fuchsia);
  border: 2px solid var(--fuchsia);
}
.map-input:disabled + .map-container--icon {
  background-color: #abadaf9b;
}
.map-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
}

.map-input:focus,
.map-input:hover,
.map-input:focus-visible,
.map-input:active,
.map-input:checked {
  outline: none;
}

.popUp {
  position: relative;
  display: flex;
  border: unset;

  align-self: flex-start;
  margin: 10px 10px 0 10px;
}
.popUp svg {
  color: var(--secondText);
}

.hover-popUp {
  position: absolute;
  top: -11.5rem;

  right: -10.5rem;
  padding: 0 0 3rem 0;

  transition: right 0.4s, top 0.4s;
  display: flex;

  text-align: center;
  width: 0;

  -webkit-filter: opacity(0);
  filter: opacity(0);
  z-index: 0;
  overflow: hidden;
}
.hover-popUp-display {
  padding: 10px;
}
.hover-popUp > p {
  color: black;
  background: var(--white);

  border-radius: 8px;
  border: 1px solid var(--fuchsia);
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.popUp-icon-container {
  cursor: pointer;
}

.popUp-hover-true {
  width: 15rem;
  -webkit-filter: opacity(1);
  filter: opacity(1);
  transition: right 0.4s, top 0.4s, -webkit-filter 0.4s;
  transition: right 0.4s, top 0.4s, filter 0.4s;
  transition: right 0.4s, top 0.4s, filter 0.4s, -webkit-filter 0.4s;
  top: -11.5rem;

  right: -2.5rem;
}

.popUp-icon {
  top: -8px;
  position: absolute;
  border-radius: 50%;
  color: var(--fuchsia);
  background-color: var(--white);
  border: 1px solid var(--fuchsia);

  right: -13px;
  font-size: 12px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 0;
  min-height: 20px;
  min-width: 20px;
}
