.profilepage__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 0.5rem 0 0.5rem;
}

.profilepage__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 43.65px;
  color: var(--purple);
  border-bottom: 1px solid #864096;
}

.profilepage__subtitle {
  font-weight: bolder;
  font-size: 20px;
  line-height: 27.28px;
  letter-spacing: 0.1px;
  color: var(--purple);
}

.profilepage__line {
  height: 1px;
  width: 90%;
  background-color: var(--purple);
}

.profilepage__line-title {
  margin-top: -20px;
  width: 100%;
}

.profilepage__container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 20px;
  margin-bottom: 50px;
  box-shadow: 0px 1px 3px 1px rgba(51, 51, 51, 0.15),
    0px 1px 2px rgba(51, 51, 51, 0.3);
  border-radius: 8px;
}

.profilepage__container-secondary {
  min-width: 40%;
  margin: 10px 0;
}

.profilepage__container-align_left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}
.profilepage__container-secondary .profilepage__subtitle {
  border-bottom: 1px solid #cfa6d9;
}
.profilepage__header {
  display: flex;
  flex-direction: row;
  gap: 4%;
  border-bottom: 1px solid #cfa6d9;
}

.profilepage__container-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 0 0 0;
  gap: 2.5rem 3rem;
}

.profilepage__container-column {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 1rem;
}

.profilepage__button-container {
  background-color: transparent;
  border: none;
  outline: none;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.82px;
  letter-spacing: 0.1px;
  margin: 0;
  cursor: pointer;
  color: #a04db3;
}

.profilepage__center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.profilepage__button-container:hover {
  font-weight: 700;
}
.profilepage__save-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 3rem 0 0 0;
}

@media (max-width: 920px) {
  .profilepage__container-body {
    display: flex;
    flex-direction: column;
  }
}
.total-invitation-profile-container {
  margin: 1rem 0 0 0;

  padding: 2rem 0;
}
.total-invitation-profile-container svg {
  color: #e33d8c;
  font-size: 20px;
}
.total-invitation-profile-container .campform__main {
  margin: 1rem 0 0 0;
}
.campform__text {
  margin: 0;
}
