.primary-button,
.secondary-button {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 40px;
  min-height: 40px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 10px 0;
  padding: 9px 16px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: inline;
  overflow: hidden;
  overflow-wrap: break-word;
  border: none;
  cursor: pointer;
}
.primary-button {
  background-color: #e33d8c;
  color: var(--defaultBackground);
  fill: var(--defaultBackground);
}
.primary-button:hover {
  color: var(--defaultBackground);
}

.primary-button svg,
.secondary-button svg {
  margin: -5px 10px -5px 0px;
  font-size: 18px;
}

.secondary-button:focus,
.secondary-button {
  outline: 2px solid;
}

.secondary-button {
  background-color: transparent;
  color: #e33d8c;
}
.secondary-button:hover {
  color: #e33d8c;
}
button:active {
  transform: scale(0.98);
}
