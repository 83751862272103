.Import-guest-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-height: 50vh;
}
.Import-guest-container p {
  color: var(--textPurple);
}
.import-guest-steps-title {
  align-self: flex-start;
}
.import-guest-steps {
  margin: 1rem 0 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: flex-start;
}
.import-guest-step {
  display: grid;
  grid-template-columns: 2rem 1fr;
  gap: 1rem;
  align-items: baseline;
}
.import-guest-step > span {
  position: relative;
  border: 1px solid var(--turquoise);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--turquoiseDark19);
}
.import-guest-step > span::after {
  position: absolute;
  content: "";
  background-color: var(--turquoiseLight88);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
.import-guest-step > p {
  margin: 0;
}
.import-guest-import-container {
  background-color: var(--turquoiseLight94);
  border: 1px solid var(--turquoiseLight75);
  width: 100%;
  padding: 1rem 0 0.5rem 0;
  text-align: center;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.import-guest-import-container > svg {
  border: 2px solid var(--purple);
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
  color: var(--purple);
  padding: 0.6rem;
}
.import-guest-import-container > p {
  color: var(--purple);
  margin: 0;
}
.import-guest-import-container > p:first-of-type {
  margin: 3px 0 0 0;
}

.import-guest-import-button {
  background-color: var(--white);
}
.import-guest-import-rules {
  display: flex;
  gap: 2rem;
  align-self: flex-start;
  color: var(--blueLight66);
}
.import-guest-import-rules > p {
  content: "\2022";
  color: var(--blueLight66);
}
.import-guest-add-button {
  align-self: flex-end;
}
.error-file {
  border: 1px solid red;
  background-color: #ffeeee;
}
.error-file .import-guest-import-button {
  background-color: var(--fuchsia);
}
.error-file-text {
  color: red;
}
