/*  footer */

.footer {
  background-color: #43204b;
  height: 100%;
  width: 100%;
}
.footer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.5rem 0;
}

.footer-content-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem 4rem;
  color: var(--secondText);
}
.footer-content-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.footer-nav-container {
  display: flex;
  gap: 1rem;
  padding: 0;
  justify-content: space-around;
}
.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer-nav-item {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  padding: 0.3rem;
}
.footer .footer-nav-item:hover {
  color: #ffffff;

  background: rgba(246, 237, 247, 0.2);
  border-radius: 4px;
  padding: 0.3rem;
}
.footer-dropdown {
  height: 0rem;
  overflow: hidden;
  transition: all 0.5s;
  background-color: var(--defaultBackground);
  width: 5rem;
  border-radius: 16px;
  color: var(--text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.footer-dropdown .footer-nav-item {
  color: var(--text);
}
.footer-dropdown-container {
  width: 5rem;
}
.active-dropdown + .footer-dropdown {
  height: 5rem;
}

.footer-dropdown-container .footer-nav-item {
  margin: 0;
}
.footer-lenguage {
  position: relative;
}

.footer-dropdown-container .footer-lenguage::after {
  content: url("https://api.iconify.design/material-symbols/arrow-back-ios-rounded.svg?color=%23e33d8c");
  position: absolute;
  color: var(--fuchsia);
  margin: auto 0 auto 0.5rem;
  left: 67%;
  width: 1rem;
  transform: rotate(-180deg);
  transition: all 0.5s;
}
.footer-dropdown-container .active-dropdown::after {
  transform: rotate(-90deg);
  top: -3px;
  left: 78%;
}
.footer-idioms {
  height: 0rem;
  overflow: hidden;
  transition: all 0.5s;
  background-color: var(--defaultBackground);
  width: 5rem;
  border-radius: 16px;
  color: var(--text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.footer-idioms .footer-nav-item {
  color: var(--text);
  width: 100%;
  text-align: center;
}
.footer-idioms .footer-nav-item:hover {
  color: var(--secondText);
  background-color: var(--fuchsia);
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
.footer-idioms-container {
  width: 5rem;
}
.active-idioms + .footer-idioms {
  height: 5rem;
  padding: 0.5rem;
}

.footer-idioms-container .footer-nav-item {
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footer-lenguage {
  position: relative;
}

.footer-lenguage::after {
  content: url("https://api.iconify.design/material-symbols/arrow-back-ios-rounded.svg?color=%23e33d8c");
  position: absolute;
  color: var(--fuchsia);
  margin: 0 0 -2px 0.5rem;
  top: -7px;
  left: 100%;
  height: 100%;
  width: 1rem;
  transform: rotate(-180deg);
  transition: all 0.5s;
}
.active-idioms::after {
  transform: rotate(-90deg);
  top: -3px;
}

.social-media {
  display: flex;
  gap: 3rem;
}

.footer-newsletter-title {
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  margin: 0;
}
.footer-newsletter-content-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.footer-newsletter-form {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
  flex-wrap: wrap;
}

.footer-label-email {
  display: inline-block;
  margin: 1rem 0 0.5rem 1rem;
}
.footer-checkbox-container {
  position: relative;
  margin: 0 0 0 1rem;
  display: flex;
  gap: 0.5rem;
  width: 100%;
  height: 1.5rem;
}
.footer-checkbox-container .error-point::after {
  right: -0.25rem;
}
.footer-label-check {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.footer-button-newsletter {
  margin: 0;
}
.footer-inputs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 780px) {
  .footer-nav-container {
    padding: 0 0 0 3rem;
  }
}

/* .hola {
  display: none;
} */
.dropdown-events-container {
  width: 8rem;
  padding: 0rem;
}
.dropdown-events-container .footer-nav-item {
  width: 100%;
  padding: 0.3rem 0.4rem;
  text-align: center;
  z-index: 4;
}
.dropdown-events-container .footer-nav-item:hover {
  color: var(--secondText);
  background-color: var(--fuchsia);
  border-radius: 10px;
}
.active-dropdown + .dropdown-events-container {
  height: 11rem;
  padding: 0.2rem;
}

@media (min-width: 780px) {
  .footer-nav-container {
    padding: 0 0 0 3rem;
  }
}
