.signUp__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--defaultBackground);
  /* height: 50vh; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 1.5rem;
}
.signUp__main h1 {
  font-weight: 700;
  font-size: calc(32px + (48 - 32) * ((100vw - 450px) / (1400 - 450)));

  line-height: 65px;
  color: var(--text);
  text-align: center;
}
.signUp__main h2 {
  font-weight: 700;
  font-size: calc(26px + (36 - 26) * ((100vw - 450px) / (1400 - 450)));

  line-height: 49px;
  color: var(--text);
  text-align: center;
}
.signUp__img {
  max-width: 100%;
  width: calc(250px + (350 - 250) * ((100vw - 450px) / (1400 - 450)));
}
.signUp__text {
  font-size: 1.3em;
  margin: 0 0 30px 0;
  overflow-wrap: break-word;
  width: 85%;
  text-align: center;
}
.log-container-block {
  position: static;
}
