.preview {
  padding: 1rem;
}

.preview-button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 48px;
  max-width: 42rem;
  margin: 2rem auto 4rem auto;
}
.preview-email-button,
.preview-invitation-button,
.preview-invitation-button:focus {
  border: 1px solid #6b3378;
  text-decoration: none;
  background: none;
  font-weight: 700;
  cursor: pointer;
  color: #6b3378;
}
.preview-email-button:active,
.preview-invitation-button:active {
  transform: scale(0.99);
}
.preview-email-button {
  border-radius: 0.825rem 0 0 0.825rem;
}
.preview-invitation-button {
  border-radius: 0 0.825rem 0.825rem 0;
}

.active-email-button {
  background: var(--primaryColor);
  color: #fff;
}
@media (min-width: 768px) {
  .preview-email-button,
  .preview-invitation-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
  }
}

.preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}
.preview-options {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.preview-option {
  display: flex;
  align-items: center;
  gap: 0 1rem;
}
.preview-options p {
  font-size: 16px;
  margin: 0;
}
.container-email-body {
  border-radius: 0.825rem;
  margin: 0 auto 2rem auto;
  grid-column: span 2;
  width: 100%;
  border: 1px solid #6b3378;
  background-color: #fff;
}
.message-wrapper h1,
.message-wrapper p {
  margin: 0 auto;
}

.email-info-field {
  display: flex;
  gap: 1.4rem 1rem;
  align-items: center;
}
.email-info-field p:first-child {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #52225d;
}

.email-body-textarea .info-input-container {
  height: 39px;
  width: 250px;
}
.email-edit-button-container {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-start;
}
.email-sender {
  background-color: #efe1f2;
  min-height: 6rem;
  margin: 0 0 2rem 0;
  border-radius: 0.825rem 0.825rem 0 0;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}
.email-sender-event-name {
  margin: 0;
  font-weight: 400;
  font-size: 19.6461px;
  line-height: 27px;
}

.email-sender-input {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.email-event-name-container {
  display: flex;
}
.email-sender-email {
  margin: 0;
}
.email-body-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 53px;
  color: #5e2d69;
}

.email-input-title {
  width: 250px;
}
p.email-date,
p.email-address {
  color: #6b3378;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  margin: 0 auto 34px auto;
}
.email-edit-button {
  border: none;
  background-color: unset;
}

.email-title-content {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.0809876px;
  color: #0d060f;
}
.main-email {
  padding: 1rem;
}
.email-body {
  font-family: "Nunito", sans-serif;
  color: #162032;
  border-radius: 0.825rem;
  background-color: #efe1f2;
  padding: 25px 15px 100px 15px;
  margin: 0 0.5rem 1rem 0.5rem;
}
.email-body-input {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.email-body-input .info-input-container {
  width: unset;
}
.email-body-textarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  gap: 2rem;
}

.email-body-textarea .input-ok::after,
.email-body-textarea .error::after {
  bottom: unset;
}
.email-body-textarea .error-text {
  bottom: -25%;
  margin: 0;
}
.email-body-textarea textarea {
  resize: none;
  width: inherit;
  height: 140px;
  padding: 1rem;
}
.email-body-textarea .info-input-container {
  height: 140px;
}

.img {
  height: auto;
}

.email-title-p {
  margin: 20px auto;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.email-body-textarea-p p {
  margin: 0 auto;
}
.email-title-p button {
  position: absolute;
  top: -9px;
  left: 101%;
  margin: auto;
}

.body-table {
  color: #162032;
  font-family: "Nunito", sans-serif;
  border-radius: 0.825rem;
  width: 100%;
  background-color: #fff;
}

.align-center {
  text-align: center;
  margin: auto;
}

.logo {
  width: 200px;
  margin: 16px 0 40px 0;
}

.message-wrapper {
  background-color: #ffffff;
  border-radius: 15px;
}

.message {
  font-size: 160%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.social-media-link {
  margin-right: 15px;
}

.social-media-icon {
  width: 30px;
  -webkit-filter: brightness(0) saturate(100%) invert(21%) sepia(60%)
    saturate(862%) hue-rotate(248deg) brightness(100%) contrast(91%);
  filter: brightness(0) saturate(100%) invert(21%) sepia(60%) saturate(862%)
    hue-rotate(248deg) brightness(100%) contrast(91%);
}
.message-wrapper h1 {
  font-weight: 400;
  font-size: 19.437px;
  line-height: 27px;
  color: #0d060f;
}
.message-wrapper .postcard--size {
  width: 250px;
  margin: 0 auto;
  box-shadow: 8px 9px 25px 0px #000000b0;
}
.copyright-container {
  width: 100%;
}
.copyright-container p {
  display: inline;
}
@media (min-width: 1000px) {
  .email-body {
    padding: 25px 27px 100px 27px;
    margin: 0 1rem 1rem 1rem;
  }
  .email-body-title {
    font-size: 25px;
    margin: 0;
  }
  .email-title-p button {
    top: -37%;

    left: 90%;
  }
  .message-wrapper h1,
  .message-wrapper p,
  .message-wrapper,
  textarea {
    max-width: 100%;
  }
  .message-wrapper img {
    width: 500px;
  }

  .email-body-textarea .info-input-container {
    width: 300px;
    height: 8rem;
    z-index: 50;
  }
  .email-body-textarea textarea {
    width: inherit;
  }
  .copyright-container {
    width: 54%;
  }
}
.footer-email {
  font-size: 10px;
  padding: 0 0 16px 0;
}
.copyright-container {
  margin: 16px auto;
}
.footer-email a,
.copyright-container a,
.email-newsletter a {
  font-weight: 700;
  font-size: 12.958px;
  line-height: 18px;
  text-decoration: none;
  color: #e33d8c;
}
.email-newsletter p {
  display: inline;
}

.social-media-link {
  margin-right: 8px;
}

.social-media-icon {
  width: 20px;
}
.cta-button a {
  min-width: 180px;
  height: 44px;
  margin: 10px 0;
  padding: 9px 19px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
  overflow: hidden;
  overflow-wrap: normal;
  border: none;
  margin: 10px 10px;
}

.email-newsletter {
  margin: 0 auto;
}
.container-email-body .separation {
  margin: 0 auto 3rem auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.message-wrapper .invitation--frame {
  margin: auto;
  margin-bottom: 30px;
  height: 278px;
  width: 200px;
}
.message-wrapper .postcard--postal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.message-wrapper .postcard--size {
  transform: scale(0.25);
  top: 0;
  right: auto;
  left: auto;
  bottom: 0;
  margin: auto !important;
  position: absolute !important;
}
@media only screen and (min-width: 480px) {
  .message-wrapper .postcard--size {
    transform: scale(0.35);
  }
  .preview {
    padding: 2rem;
  }
  .message-wrapper .invitation--frame {
    margin: auto;
    margin-bottom: 30px;
    height: 500px;
    width: 200px;
  }
  .cta-button a {
    display: inline;
  }
}
@media only screen and (min-width: 720px) {
  .message-wrapper .postcard--size {
    transform: scale(0.4);
  }
  .preview {
    padding: 2rem;
  }
  .message-wrapper .invitation--frame {
    margin: auto;
    margin-bottom: 30px;
    height: 500px;
    width: 200px;
  }
}
@media only screen and (min-width: 1440px) {
  .email-title-p button {
    left: 100%;
  }
}
